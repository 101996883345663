<script>
import HrbrPdfViewerThumbnail from './HrbrPdfViewerThumbnail.vue';

export default {
  name: 'HrbrPdfViewerPreview',

  components: {
    HrbrPdfViewerThumbnail,
  },

  props: {
    pages: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },

  computed: {
    renderedPages() {
      return this.pages.filter((page) => page.pageIsRendered);
    },
  },

  methods: {
    onThumbnailClick(pageNumber) {
      this.$emit('thumbnail-click', pageNumber);
    },

    onThumbnailRendered(payload) {
      this.$emit('thumbnail-rendered', payload);
    },

    onThumbnailError(payload) {
      this.$emit('thumbnail-error', payload);
    },
  },
};
</script>

<template>
  <div class="hrbr-pdf-viewer-preview">
    <HrbrPdfViewerThumbnail
      v-for="page in renderedPages"
      :key="page.pageId"
      v-bind="{ page, pages, currentPage }"
      @thumbnail-click="onThumbnailClick"
      @thumbnail-rendered="onThumbnailRendered"
      @thumbnail-error="onThumbnailError">
    </HrbrPdfViewerThumbnail>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-pdf-viewer-preview {
  position: absolute;
  top: var(--header-height);
  left: 0;
  bottom: 0;
  width: var(--preview-width);
  padding: 15px 20px;
  z-index: 1;
  overflow: auto;
  background-color: rgba(42, 42, 46, 0.4);
  box-shadow: inset -1px 0 0 rgb(0 0 0 / 10%);
}
</style>
