<script>
import Vue from 'vue';
import HrbrPdfViewerHeader from './HrbrPdfViewerHeader.vue';
import HrbrPdfViewerPreview from './HrbrPdfViewerPreview.vue';
import HrbrPdfViewerDocument from './HrbrPdfViewerDocument.vue';
import { range, floor } from '@/utils/helpers/functions';

const FONTS_PATH = 'src/assets/fonts/pdfjs_standard_fonts/';
const FONTS_URL = new URL(FONTS_PATH, window.location.origin).toString();

// https://github.com/mozilla/pdf.js/blob/master/src/display/api.js
const DOCUMENT_LOADING_PARAMS = {
  isOffscreenCanvasSupported: true,
  standardFontDataUrl: FONTS_URL,
  useWorkerFetch: true,
  useSystemFonts: true,
  // pdfBug: true, // for debugging
};

async function getPdfjsDocument(file) {
  const loadingTask = await Vue.prototype.$pdfjs.getDocument({
    url: file,
    ...DOCUMENT_LOADING_PARAMS,
  });
  const document = await loadingTask.promise;
  return document;
}

async function getPdfjsPages(pdf, firstPage, lastPage) {
  const allPagesPromises = range(firstPage, lastPage + 1).map((num) => pdf.getPage(num));
  const allPages = await Promise.all(allPagesPromises);
  return allPages;
}

export default {
  name: 'HrbrPdfViewer',

  components: {
    HrbrPdfViewerHeader,
    HrbrPdfViewerPreview,
    HrbrPdfViewerDocument,
  },

  props: {
    file: {
      // PDF url, base64
      type: String,
    },
    fileName: {
      type: String,
      default: 'my_doc.pdf',
    },
    fileInfo: {
      // Additional PDF info
      type: Object,
      default() {
        return {
          fileId: null,
        };
      },
    },
    outputScale: {
      type: Number,
      default: 2,
      validator(value) {
        return value >= 1;
      },
    },
    mode: {
      type: String,
      default: 'view',
      validator(value) {
        const allowedValues = ['view', 'edit'];
        return allowedValues.includes(value);
      },
    },
    isTextLayer: {
      type: Boolean,
      default: false,
    },
    draggableAnnotations: {
      type: Array,
      default: () => [],
    },
    draggableInputs: {
      type: Array,
      default: () => [],
    },
    editorFiles: {
      type: Array,
      default: () => [],
    },
    editorPages: {
      type: Array,
      default: () => [],
    },
    isEditingEnabled: {
      type: Boolean,
      default: false,
    },
    showPageActions: {
      type: Boolean,
      default: false,
    },

    customPageOverlay: {
      type: Object,
      required: false,
    },

    isOnlySignerPreview: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      pdf: null,
      pages: [],
      currentPage: 1,
      scale: 1, // zoom in/out scale
      fit: null,
      isPreviewEnabled: false,
      isPagesLoading: false,
      isDocumentLoading: false,
      isDocumentRendered: false,
    };
  },

  computed: {
    pageCount() {
      return this.pages.length || 0;
    },
    showSpinner() {
      return this.isOnlySignerPreview ? !this.isDocumentRendered : !this.isDocumentRendered;
    }
  },

  watch: {
    file: {
      handler(file) {
        this.getDocument(file);
      },
      immediate: true,
    },
    pdf: {
      handler(pdf, oldPdf) {
        if (!pdf) return;
        if (oldPdf) this.resetValues();
        this.getPages();
      },
    },
  },

  methods: {
    onPageRendered(page) {
      if (!this.isDocumentRendered) this.checkDocumentRendered(page);
      page.pageIsRendered = true;
      this.$emit('page-rendered', page);
    },

    onPageMouseEnter(event) {
      this.$emit('page-mouse-enter', event);
    },

    onPageMouseLeave(event) {
      this.$emit('page-mouse-leave', event);
    },

    onPageLayerDrop(payload) {
      this.$emit('page-layer-drop', payload);
    },

    onPageInsert(pageId) {
      this.$emit('page-insert', pageId);
    },

    onPageRemove(pageId) {
      this.$emit('page-remove', pageId);
    },

    onPageDownload(pageId) {
      this.$emit('page-download', pageId);
    },

    onFileDownload(pageId) {
      this.$emit('file-download', pageId);
    },

    onCheckboxResize(annotation) {
      this.$emit('checkbox-resize', annotation);
    },

    onCheckboxDrag(annotation) {
      this.$emit('checkbox-drag', annotation);
    },

    onPageChange(pageNumber) {
      this.updateCurrentPage(pageNumber);
      this.scrollToPage(pageNumber);
    },

    onDraggableAnnotationActivated(payload) {
      this.$emit('draggable-annotation-activated', payload);
    },

    updatePages(pages) {
      this.pages = pages;
    },

    updateCurrentPage(pageNumber) {
      this.currentPage = pageNumber;
    },

    updateScale(scale) {
      const roundedScale = floor(scale, 2);
      this.scale = roundedScale;
    },

    updateFit(fit) {
      this.fit = fit;
    },

    checkDocumentRendered(page) {
      if (this.isDocumentRendered) return;
      const pageIdx = this.pages.findIndex((i) => i.pageId === page.pageId);
      const pagesLength = this.pages.length;

      if (pageIdx === -1) {
        // if for some reason the page is not found
        this.$emit('document-error');
        this.isDocumentRendered = true;
        return;
      }
      if (pageIdx === pagesLength - 1) {
        this.isDocumentRendered = true;
      }
    },

    togglePreview() {
      this.isPreviewEnabled = !this.isPreviewEnabled;
    },

    async getDocument(file) {
      if (!file) return;

      try {
        this.isDocumentLoading = true;

        const pdfId = this.fileInfo.fileId || this.generatePdfId(this.fileName);
        const pdfjsDocument = await getPdfjsDocument(file);

        const pdf = {
          pdfId,
          pdfjsDocument,
        };

        this.pdf = pdf;

        this.$emit('document-loaded', file);
      } catch (e) {
        this.$emit('document-error', e);
        console.error(e);
      } finally {
        this.isDocumentLoading = false;
      }
    },

    async getPages() {
      if (!this.pdf) return;

      const { pdfjsDocument } = this.pdf;

      const firstPageNum = 1;
      const lastPageNum = pdfjsDocument.numPages;

      try {
        this.isPagesLoading = true;

        const pdfjsPages = await getPdfjsPages(pdfjsDocument, firstPageNum, lastPageNum);

        const mapPages = (pdfjsPage) => {
          const viewport = pdfjsPage.getViewport({ scale: this.outputScale });
          return {
            pdfId: this.pdf.pdfId,
            pageId: this.generatePageId(),
            pageOriginalNumber: pdfjsPage.pageNumber,
            pageScaleFactor: this.outputScale,
            pageWidth: viewport.width,
            pageHeight: viewport.height,
            pageIsActive: true,
            pageIsRendered: false,
            pdfjsPage,
          };
        };

        let pages = pdfjsPages.map(mapPages);
        if (this.mode === 'edit') {
          pages = await this.handlePagesForEditMode(pages);
        }

        this.pages = pages;

        this.$emit('pages-loaded', this.pages);
      } catch (e) {
        this.$emit('document-error', e);
        console.error(e);
      } finally {
        this.isPagesLoading = false;
      }
    },

    async onAddPages({ file, position, addType }) {
      if (!file) return;

      try {
        const firstPageNum = 1;
        const pdfjsDocument = await getPdfjsDocument(file.fileBase64);
        const pdfjsPages = await getPdfjsPages(pdfjsDocument, firstPageNum, pdfjsDocument.numPages);

        const mapPages = (pdfjsPage) => {
          const viewport = pdfjsPage.getViewport({ scale: this.outputScale });
          return {
            pdfId: file.fileId,
            pageId: this.generatePageId(),
            pageOriginalNumber: pdfjsPage.pageNumber,
            pageScaleFactor: this.outputScale,
            pageWidth: viewport.width,
            pageHeight: viewport.height,
            pageIsActive: true,
            pageIsRendered: false,
            pdfjsPage,
          };
        };

        const pages = pdfjsPages.map(mapPages);

        if (position === 'ATSTART') {
          this.pages.unshift(...pages);
        } else if (position === 'ATEND') {
          this.pages.push(...pages);
        } else {
          const idx = this.pages.findIndex((page) => page.pageId === position);
          if (idx !== -1) this.pages.splice(idx + 1, 0, ...pages);
        }

        this.$emit('pages-added', {
          pages: this.pages,
          pdfId: file.fileId,
          addType,
        });

        const [firstPage] = pages;
        this.$nextTick(() => {
          this.scrollToPageById(firstPage.pageId);
        });
      } catch (e) {
        console.error(e);
      }
    },

    async handlePagesForEditMode(pages) {
      const { editorPages, editorFiles } = this;
      if (!editorPages.length || !editorFiles.length) return pages;

      let resultPages = [...pages];

      // filter deleted pages from the original pdf
      resultPages = resultPages.filter(
        ({ pageOriginalNumber, pdfId }) =>
          !!this.getEditorPageByNumber({ pageOriginalNumber, pdfId }),
      );

      // overwrite new generated page id with the existing one
      resultPages = resultPages.map((page) => {
        const { pageOriginalNumber, pdfId } = page;
        const editorPage = this.getEditorPageByNumber({ pageOriginalNumber, pdfId });
        if (!editorPage) return page;
        return { ...page, pageId: editorPage.pageid };
      });

      const notOriginalEditorFiles = editorFiles.filter((file) => file.pdfid !== this.pdf.pdfId);
      const notOriginalEditorPages = editorPages.filter((page) => page.pdfid !== this.pdf.pdfId);

      // build pdfjs document index of editor files by pdf id
      const pdfjsDocumentsByPdfId = await this.buildPdfjsDocumentsIndexByPdfId(
        notOriginalEditorFiles,
      );

      // add pages not from the original pdf
      for (const editorPage of notOriginalEditorPages) {
        const pageNum = editorPage.pageoriginalpdfpagenumber;
        const pdfjsDocument = pdfjsDocumentsByPdfId.get(editorPage.pdfid);
        const pdfjsPage = await pdfjsDocument.getPage(pageNum);
        const page = this.getPageInfoFromEditorPage({ editorPage, pdfjsPage });
        const pageIdx = editorPages.findIndex((p) => p.pageid === editorPage.pageid);
        resultPages.splice(pageIdx, 0, page);
      }
      return resultPages;
    },

    async buildPdfjsDocumentsIndexByPdfId(editorFiles) {
      const pdfjsDocumentsIndex = new Map();
      for (const editorFile of editorFiles) {
        const pdfjsDocument = await getPdfjsDocument(editorFile.pdfbase64);
        pdfjsDocumentsIndex.set(editorFile.pdfid, pdfjsDocument);
      }
      return pdfjsDocumentsIndex;
    },

    getEditorPageByNumber({ pageOriginalNumber, pdfId }) {
      const page = this.editorPages.find(
        (editorPage) =>
          editorPage.pageoriginalpdfpagenumber === pageOriginalNumber && editorPage.pdfid === pdfId,
      );
      return page;
    },

    getPageInfoFromEditorPage({ editorPage, pdfjsPage }) {
      return {
        pdfId: editorPage.pdfid,
        pageId: editorPage.pageid,
        pageOriginalNumber: editorPage.pageoriginalpdfpagenumber,
        pageScaleFactor: editorPage.pagescalefactor,
        pageWidth: editorPage.pagewidthpx,
        pageHeight: editorPage.pageheightpx,
        pageIsActive: editorPage.pageisactive,
        pageIsRendered: false,
        pdfjsPage,
      };
    },

    onRemovePage(pageId) {
      const pages = this.pages.filter((page) => page.pageId !== pageId);
      this.updatePages(pages);
    },

    attachEventHandlers() {
      if (this.mode === 'edit') {
        this.$parent.$on('pdf-viewer-add-pages', this.onAddPages);
        this.$parent.$on('pdf-viewer-remove-page', this.onRemovePage);
      }
    },

    resetValues() {
      this.pages = [];
      this.currentPage = 1;
      this.scale = 1;
      this.fit = null;
      this.isPreviewEnabled = false;
      this.isDocumentRendered = false;
    },

    scrollToPage(pageNumber) {
      const document = this.$refs.document.$el;
      const pageElem = this.getPageElement(pageNumber);
      document.scrollTop = pageElem.offsetTop;
    },

    scrollToPageById(pageId) {
      const document = this.$refs.document.$el;
      const pageElem = this.getPageElementById(pageId);
      document.scrollTop = pageElem.offsetTop;
    },

    getPageElement(pageNumber) {
      return this.$el.querySelector(`.hrbr-pdf-viewer-page[data-page-number="${pageNumber}"]`);
    },

    getPageElementById(pageId) {
      return this.$el.querySelector(`.hrbr-pdf-viewer-page[data-page-id="${pageId}"]`);
    },

    generatePdfId(fileName) {
      return `pdf-${fileName.replace(/\W/g, '')}-${Math.random()
        .toString(36)
        .slice(2)}-${Date.now()}`;
    },

    generatePageId() {
      return `page-${Math.random().toString(36).slice(2)}-${Date.now()}`;
    },

    setElementFocus(element) {
      if (!element) return;
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
        blockOffset: '100px',
      });
    }
  },

  created() {
    this.attachEventHandlers();
  },
};
</script>

<template>
  <div :class="['hrbr-pdf-viewer', isOnlySignerPreview && 'only-signer']">
    <HrbrPdfViewerHeader
      v-bind="{
        file,
        fileName,
        scale,
        outputScale,
        fit,
        pageCount,
        currentPage,
        isPreviewEnabled,
        mode,
        hasToolbar: isOnlySignerPreview
      }"
      @toggle-preview="togglePreview"
      @page-change="onPageChange"
      @scale-change="updateScale"
      @fit-change="updateFit"
      @file-download="onFileDownload">
    </HrbrPdfViewerHeader>

    <HrbrPdfViewerPreview
      v-bind="{ pages, currentPage, isPreviewEnabled }"
      v-show="isPreviewEnabled"
      @thumbnail-click="onPageChange">
    </HrbrPdfViewerPreview>

    <div
      id="hrbr-pdf-viewer"
      class="hrbr-pdf-viewer__main"
      data-testid="pdfviewer-container"
    >
      <HrbrPdfViewerDocument
        v-bind="{
          pdf,
          pages,
          currentPage,
          pageCount,
          scale,
          outputScale,
          fit,
          mode,
          isTextLayer,
          draggableAnnotations,
          draggableInputs,
          isEditingEnabled,
          showPageActions,
          customPageOverlay
        }"
        @set-element-focus="setElementFocus"
        @page-focus="updateCurrentPage"
        @scale-change="updateScale"
        @fit-change="updateFit"
        @page-rendered="onPageRendered"
        @page-mouse-enter="onPageMouseEnter"
        @page-mouse-leave="onPageMouseLeave"
        @page-layer-drop="onPageLayerDrop"
        @page-insert="onPageInsert"
        @page-remove="onPageRemove"
        @file-download="onFileDownload"
        @checkbox-resize="onCheckboxResize"
        @checkbox-drag="onCheckboxDrag"
        @draggable-annotation-activated="onDraggableAnnotationActivated"
        ref="document">
      </HrbrPdfViewerDocument>
      <div class="hrbr-pdf-viewer__overlay" v-if="showSpinner">
        <i class="fa-light fa-spinner-third fa-spin"></i>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-pdf-viewer {
  --header-height: 36px;
  --preview-width: 180px;

  width: 100%;
  height: 100%;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgb(0 0 0 / 10%) 0 1px 3px 0;

  &.only-signer {
    --header-height: 52px;
  }

  &__main {
    position: absolute;
    top: var(--header-height);
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: rgba(255, 255, 255, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      font-size: 50px;
      color: #2d71ad;
      animation-duration: 1s;
    }
  }
}
</style>
