<script setup>
import { ref } from 'vue';
import { TOOLBAR_ITEMS } from '@/config/agreement-editor.js';

const props = defineProps({
  fieldType: {
    type: String,
    required: true
  },
  maxHeight: {
    type: Number,
  },
  dropdownPosition: {
    type: String,
  }
});
const emit = defineEmits(['change']);

const dropdownOpened = ref(false);

const getSelectedItemIcon = (type) => {
  return TOOLBAR_ITEMS.find(item => {
    return item.type === type
  }).inputtileicon;
};

const closeDropdown = () => {
  dropdownOpened.value = false;
}
</script>

<template>
  <div
    v-bind="$attrs"
    class="type-switch"
    v-click-outside="closeDropdown"
  >
    <div
      class="type-switch__wrap"
      :style="{ maxHeight: maxHeight ? `${maxHeight - 3}px` : 'auto' }"
    >
      <div
        class="type-switch__selection"
        @click.stop="dropdownOpened = !dropdownOpened"
      >
        <i :class="['fa-light', 'fa-' + getSelectedItemIcon(fieldType), 'type-icon']"></i>
        <i v-if="!dropdownOpened" class="fa-regular fa-angle-down dropdown-arrow"></i>
        <i v-if="dropdownOpened" class="fa-regular fa-angle-up dropdown-arrow"></i>
      </div>

      <div
        v-if="dropdownOpened"
        :class="['type-switch__dropdown', dropdownPosition]"
      >
        <div
          v-for="item in TOOLBAR_ITEMS"
          :key="item.id"
          class="type-switch__dropdown-item"
          @click.stop="emit('change', item.type)"
        >
          <i :class="['fa-light', 'fa-' + item.inputtileicon]"></i>
          <span>{{ item.type }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.type-switch {
  &__wrap {
    position: absolute;
    right: 6px;
    bottom: 2px;
  }

  &__selection {
    display: flex;
    align-items: center;
    padding: 2px 5px;
    border-radius: 2px;
    background-color: #fff;
    color: #B015B3;
    max-height: inherit;
    .type-icon {
      width: 22px;
      margin-right: 6px;
      font-size: 18px;
    }
    .dropdown-arrow {
      font-size: 12px;
    }
  }
  &__dropdown {
    position: absolute;
    top: calc(100% + 5px);
    width: 160px;
    padding: 8px 0;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 #00000040;
    border-radius: 8px;
    &.r {
      right: 0;
    }
    &.l {
      left: 0;
    }
    &.t {
      top: unset;
      bottom: calc(100% + 10px);
    }
  }
  &__dropdown-item {
    display: flex;
    align-items: center;
    padding: 8px 16px;
    font-size: 14px;
    color: #333;
    font-weight: 400;
    cursor: pointer;
    &:hover {
      background-color: #DBDBDB;
    }
    i {
      width: 22px;
      margin-right: 8px;
    }
    span {
      text-transform: capitalize;
    }
  }
}
</style>
