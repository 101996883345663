<script setup>
import draggable from 'vuedraggable';
import { TOOLBAR_ITEMS } from '@/config/agreement-editor.js';
import { readFileAsDataUrl } from '@/utils/helpers/functions.js';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';
import { useHarbourStore } from '@/stores/harbour-store.js';

const harbourStore = useHarbourStore();
const magicAnnotationsStore = useMagicAnnotationsStore();

const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
  }
});

const onToolbarItemClick = (input) => {
  if (input.type === 'image') {
    return;
  }
  magicAnnotationsStore.addInput({ type: input.type, value: null, pageNum: props.currentPage - 1 });
};

const getImage = async (evt, input) => {
  const file = evt.target.files[0];
  const base64 = await readFileAsDataUrl(file);
  magicAnnotationsStore.addInput({ type: input.type, value: base64, pageNum: props.currentPage - 1 });
  evt.target.value = '';
};
</script>

<template>
  <div :class="[
    'hrbr-pdf-viewer-header__part',
    'hrbr-pdf-viewer-header__part--toolbar',
    'pdf-viewer-toolbar',
    magicAnnotationsStore.pageMode,
    harbourStore.isMobileView ? 'mobile-view' : '']
  ">
    <div class="pdf-viewer-toolbar__inputs-list">
      <draggable
        class="pdf-viewer-toolbar__draggable"
        :list="TOOLBAR_ITEMS"
        :sort="false"
        :group="{ name: 'toolbarInputs', pull: 'clone', put: false }"
        ghost-class="ghost"
        tag="div"
      >
        <div
          :class="['pdf-viewer-toolbar__input', input.type === 'image' && 'image-input']"
          v-for="input in TOOLBAR_ITEMS"
          :key="input.id"
          :data-id="input.id"
          @click="harbourStore.isMobileView ? null : onToolbarItemClick(input)"
          @touchend="onToolbarItemClick(input)"
        >
          <label v-if="input.type === 'image'" >
            <i :class="['fa-regular', 'fa-' + input.inputtileicon]"></i>
            <input
              type="file"
              accept="image/jpeg, image/png"
              hidden
              @change="evt => getImage(evt, input)"
            />
          </label>
          <i v-else :class="['fa-regular', 'fa-' + input.inputtileicon]"></i>
        </div>
      </draggable>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.pdf-viewer-toolbar {
  justify-content: center;
  &.preview {
    cursor: not-allowed;
    .pdf-viewer-toolbar__input {
      pointer-events: none;
    }
  }
  &.mobile-view {
    .pdf-viewer-toolbar__input {
      border: none;
      width: 25px;
      height: 25px;
    }
  }
  &__inputs-list {
    padding: 0 8px;
  }
  &__draggable {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    color: #333;
    border: 1px solid #DBDBDB;
    background-color: #fff;
    cursor: pointer;
    border-radius: 8px;
    &:hover {
      background-color: #DBDBDB;
    }
    &.image-input {
      padding: 3px 0;
    }
    label {
      padding: 0 12px;
      cursor: pointer;
    }
  }
}
</style>
