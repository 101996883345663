<script>
import HrbrPdfViewerToolbar from '@components/PdfViewer/HrbrPdfViewerToolbar.vue';
const DEFAULT_SCALE_DELTA = 1.1;
const MIN_SCALE = 0.5;
const MAX_SCALE = 4;

export default {
  name: 'HrbrPdfViewerHeader',

  components: {
    HrbrPdfViewerToolbar
  },

  props: {
    file: {
      type: String,
    },
    fileName: {
      type: String,
    },
    scale: {
      type: Number,
    },
    outputScale: {
      type: Number,
    },
    fit: {
      type: String,
    },
    pageCount: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },
    isPreviewEnabled: {
      type: Boolean,
    },
    mode: {
      type: String,
    },
    hasToolbar: {
      type: Boolean,
    }
  },

  data() {
    return {
      firstPage: 1,
    };
  },

  computed: {
    isNextPageDisabled() {
      return this.currentPage >= this.pageCount;
    },

    isPrevPageDisabled() {
      return this.currentPage <= this.firstPage;
    },

    isZoomDisabled() {
      return !this.scale;
    },

    isZoomInDisabled() {
      if (this.isZoomDisabled) return true;
      return this.scale >= MAX_SCALE;
    },

    isZoomOutDisabled() {
      if (this.isZoomDisabled) return true;
      return this.scale <= MIN_SCALE;
    },

    scalePercentage() {
      const scale = this.scale * 100;
      return `${Math.round(scale)}%`;
    },

    scaleOptions() {
      return [
        { value: 'page-auto', text: 'Page Fit' },
        { value: 'page-width', text: 'Page Width' },
        { value: 0.5, text: '50%' },
        { value: 0.75, text: '75%' },
        { value: 1, text: '100%' },
        { value: 1.25, text: '125%' },
        { value: 1.5, text: '150%' },
        { value: 2, text: '200%' },
        { value: 3, text: '300%' },
        { value: 4, text: '400%' },
      ];
    },

    scaleSelectValue() {
      if (this.fit) return this.fit;

      const scaleOption = this.scaleOptions.find((option) => option.value === this.scale);
      if (scaleOption) return scaleOption.value;
      return 'custom';
    },
  },

  methods: {
    updateScale(scale) {
      this.$emit('scale-change', scale);
    },

    updateFit(fit) {
      this.$emit('fit-change', fit);
    },

    updatePage(page) {
      this.$emit('page-change', page);
    },

    togglePreview() {
      this.$emit('toggle-preview');
    },

    nextPage() {
      const nextPage = this.currentPage + 1;
      if (nextPage > this.pageCount) return;
      this.updatePage(nextPage);
    },

    prevPage() {
      const prevPage = this.currentPage - 1;
      if (prevPage < this.firstPage) return;
      this.updatePage(prevPage);
    },

    onPageChange(event) {
      const newPage = parseInt(event.target.value, 10);
      if (newPage < this.firstPage || newPage > this.pageCount || Number.isNaN(newPage)) return;
      this.updatePage(newPage);
    },

    zoomIn() {
      let newScale = this.scale;
      let steps = 1;

      do {
        newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2);
        newScale = Math.ceil(newScale * 10) / 10;
        newScale = Math.min(MAX_SCALE, newScale);
      } while (--steps > 0 && newScale < MAX_SCALE);

      this.updateScale(newScale);
      this.updateFit(null);
    },

    zoomOut() {
      let newScale = this.scale;
      let steps = 1;

      do {
        newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2);
        newScale = Math.floor(newScale * 10) / 10;
        newScale = Math.max(MIN_SCALE, newScale);
      } while (--steps > 0 && newScale > MIN_SCALE);

      this.updateScale(newScale);
      this.updateFit(null);
    },

    onScaleSelectChange(event) {
      const { value } = event.target;

      if (value === 'page-width' || value === 'page-auto') {
        this.updateFit(value);
        return;
      }

      const scaleValue = parseFloat(value);
      this.updateScale(scaleValue);
      this.updateFit(null);
    },

    downloadFile() {
      if (this.mode === 'edit') {
        this.$emit('file-download');
      } else {
        this.downloadFileOriginal();
      }
    },

    async downloadFileOriginal() {
      try {
        const res = await fetch(this.file);
        const blob = await res.blob();

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = this.fileName;

        link.click();
        link.remove();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<template>
  <div :class="['hrbr-pdf-viewer-header', hasToolbar && 'with-toolbar']">
    <div class="hrbr-pdf-viewer-header__part hrbr-pdf-viewer-header__part--left">
      <div
        class="hrbr-pdf-viewer-header__btn hrbr-pdf-viewer-header__btn--preview"
        :class="{ 'hrbr-pdf-viewer-header__btn--active': isPreviewEnabled }"
        @click="togglePreview">
        <i class="fa-light fa-sidebar"></i>
      </div>

      <div class="hrbr-pdf-viewer-header__pages-btns">
        <div
          class="hrbr-pdf-viewer-header__btn hrbr-pdf-viewer-header__btn--prev-page"
          :class="{ 'hrbr-pdf-viewer-header__btn--disabled': isPrevPageDisabled }"
          @click="prevPage">
          <i class="fa-light fa-circle-arrow-up"></i>
        </div>
        <div
          class="hrbr-pdf-viewer-header__btn hrbr-pdf-viewer-header__btn--next-page"
          :class="{ 'hrbr-pdf-viewer-header__btn--disabled': isNextPageDisabled }"
          @click="nextPage">
          <i class="fa-light fa-circle-arrow-down"></i>
        </div>
      </div>

      <div class="hrbr-pdf-viewer-header__paginator">
        <input
          class="hrbr-pdf-viewer-header__paginator-input"
          :value="currentPage"
          :min="firstPage"
          :max="pageCount"
          @input="onPageChange"
          type="number" />
        <span class="hrbr-pdf-viewer-header__paginator-divider">/</span>
        <span class="hrbr-pdf-viewer-header__paginator-count">{{ pageCount }}</span>
      </div>
    </div>

    <HrbrPdfViewerToolbar
      v-if="hasToolbar"
      :current-page="currentPage"
    />

    <div class="hrbr-pdf-viewer-header__part hrbr-pdf-viewer-header__part--center">
      <div class="hrbr-pdf-viewer-header__zoom-btns">
        <div
          class="hrbr-pdf-viewer-header__btn hrbr-pdf-viewer-header__btn--zoom-out"
          :class="{ 'hrbr-pdf-viewer-header__btn--disabled': isZoomOutDisabled }"
          @click="zoomOut">
          <i class="fa-light fa-circle-minus"></i>
        </div>
        <div
          class="hrbr-pdf-viewer-header__btn hrbr-pdf-viewer-header__btn--zoom-in"
          :class="{ 'hrbr-pdf-viewer-header__btn--disabled': isZoomInDisabled }"
          @click="zoomIn">
          <i class="fa-light fa-circle-plus"></i>
        </div>
      </div>

      <div class="hrbr-pdf-viewer-header__scale">
        <select
          class="hrbr-pdf-viewer-header__scale-select"
          :value="scaleSelectValue"
          @change="onScaleSelectChange">
          <option value="custom" disabled="disabled" hidden="true">{{ scalePercentage }}</option>
          <option
            v-for="scaleOption in scaleOptions"
            :key="scaleOption.value"
            :value="scaleOption.value">
            {{ scaleOption.text }}
          </option>
        </select>
      </div>
    </div>

    <div
      v-if="!hasToolbar"
      class="hrbr-pdf-viewer-header__part hrbr-pdf-viewer-header__part--right"
    >
      <div
        class="hrbr-pdf-viewer-header__btn hrbr-pdf-viewer-header__btn--download"
        @click="downloadFile">
        <i class="fa-light fa-file-arrow-down"></i>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-pdf-viewer-header {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  gap: 10px;
  height: var(--header-height);
  padding: 5px 10px;
  background: #fff;
  box-shadow: rgb(0 0 0 / 10%) 0 -1px 0 0 inset;

  &.with-toolbar {
    .hrbr-pdf-viewer-header__part--center {
      justify-content: flex-end;
    }
  }

  &__part {
    display: flex;
    gap: 15px;
    align-items: center;

    &--left {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &__btn {
    font-size: 18px;
    color: #363636;
    cursor: pointer;
    transition: all 0.2s;

    &--active,
    &:hover {
      color: var(--hrbr-primary-color-active);
    }

    &--disabled,
    &--disabled:hover {
      color: #848484;
    }

    i {
      display: block;
    }
  }

  &__zoom-value {
    font-size: 14px;
    line-height: 1;
    text-align: center;
    min-width: 40px;
  }

  &__pages-btns,
  &__zoom-btns {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__paginator {
    font-size: 15px;
    color: #363636;
    line-height: 1.2;
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__paginator-input {
    font-size: 13px;
    width: 40px;
    height: 20px;
    padding: 4px;
    display: block;
    border: 1px solid #363636;
    border-radius: 2px;
    outline: none;
  }

  &__scale {
    width: 110px;
    height: 24px;
  }

  &__scale-select {
    font-size: 14px;
    color: #363636;
    display: block;
    height: 24px;
    padding: 0 5px;
    width: 100%;
    background: #fff;
    border-color: #dbdbdb;
    border-radius: 2px;
    box-shadow: none;
    outline: none;
    cursor: pointer;
    appearance: none;
  }
}

@media all and (max-width: 1024px) {
  .hrbr-pdf-viewer-header {
    &__pages-btns,
    &__paginator {
      display: none;
    }
  }
}

@media all and (max-width: 480px) {
  .hrbr-pdf-viewer-header {
    &__scale {
      max-width: 50px;
    }
  }
}
</style>
