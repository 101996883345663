<script>
import VueDraggableResizable from 'vue-draggable-resizable';
import { storeToRefs } from 'pinia';
import { INPUT_DPI, MAGIC_ANNOTATIONS_SIZES, OUTPUT_DPI, ANNOTATION_PADDING_HORIZONTAL, ANNOTATION_PADDING_VERTICAL } from '@/config/magic-annotations.js';
import { useHarbourStore } from '@/stores/harbour-store.js';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';
import { floor } from '@/utils/helpers/functions';
import HrbrAnnotationImage from './HrbrAnnotationImage.vue';
import HrbrAnnotationSignature from './HrbrAnnotationSignature.vue';
import HrbrTypeSwitcher from './HrbrTypeSwitcher.vue';
import HrbrAnnotationDate from './HrbrAnnotationDate.vue';

export default {
  name: 'HrbrPdfViewerPageMagicAnnotations',

  components: {
    VueDraggableResizable,
    HrbrAnnotationSignature,
    HrbrAnnotationImage,
    HrbrTypeSwitcher,
    HrbrAnnotationDate
  },

  props: {
    page: {
      type: Object,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    outputScale: {
      type: Number,
      required: true,
    },
    focusedPage: {
      type: Number,
      default: null,
    },
    isTextLayer: {
      type: Boolean,
    },
    documentScrollTop: {
      type: Number,
      default: 0,
    },
    documentScrollLeft: {
      type: Number,
      default: 0,
    },
    documentClientHeight: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String,
    },
    draggableAnnotations: {
      type: Array,
    },
    draggableInputs: {
      type: Array,
    },
    isEditingEnabled: {
      type: Boolean,
      default: false,
    },
    showPageActions: {
      type: Boolean,
    },
  },

  setup() {
    const harbourStore = useHarbourStore();
    const magicAnnotationsStore = useMagicAnnotationsStore();
    const {
      suggestionsList,
      getPageSuggestions,
      addedInputsList,
      pagesHeight,
      documentType,
      firstPageNum,
      pageMode,
      scrollPosition,
      pagesScale,
      pagesWidth,
      firstAnnotationRef,
      focusedSuggestionIndex,
    } = storeToRefs(magicAnnotationsStore);

    return {
      harbourStore,
      magicAnnotationsStore,
      suggestionsList,
      getPageSuggestions,
      addedInputsList,
      pagesHeight,
      documentType,
      firstPageNum,
      pageMode,
      scrollPosition,
      pagesScale,
      pagesWidth,
      firstAnnotationRef,
      focusedSuggestionIndex,
    };
  },

  data() {
    return {
      viewport: null,
      pageElementTop: 0,
      pageElementHeight: 0,
      fontSizeMin: 16,
      typeToggleMinWidth: 50,
      textInputMaxHeight: 60,
      firstRefSet: false,
      horizontalPaddingPercentage: ANNOTATION_PADDING_HORIZONTAL,
      verticalPaddingPercentage: ANNOTATION_PADDING_VERTICAL
    };
  },

  inject: {
    getAnnotationIdx: {
      default() {
        return () => 0;
      },
    },
    updateLastEditedAnnotationGeometry: {
      default() {
        return () => null;
      },
    },
    availableFonts: {
      default: () => [],
    },
    inputTiles: {
      default: () => [],
    },
  },

  computed: {
    pageSuggestions() {
      return this.magicAnnotationsStore.getAllPageInputs(this.pageNumber - 1);
    },

    docNaturalDimensions() {
      return this.getPageNaturalViewport();
    },

    pageScale() {
      return this.getPageScale();
    },

    actualPageScale() {
      return floor(this.scale / this.outputScale, 3);
    },

    actualSizeViewport() {
      return this.viewport.clone({ scale: this.scale });
    },

    pageSizes() {
      let {
        width,
        height,
      } = this.viewport;
      [width, height] = [width, height].map((dim) => Math.ceil(dim));
      return {
        width,
        height,
      };
    },

    pageActualSizes() {
      let {
        width,
        height,
      } = this.actualSizeViewport;
      [width, height] = [width, height].map((dim) => Math.ceil(dim));
      return {
        width,
        height,
      };
    },

    pageStyle() {
      const {
        width: widthPx,
        height: heightPx,
      } = this.pageActualSizes;
      const style = {
        width: `${widthPx}px`,
        height: `${heightPx}px`,
      };
      return style;
    },

    canvasAttrs() {
      const {
        width,
        height,
      } = this.pageSizes;
      return {
        width,
        height,
      };
    },

    pageIdx() {
      const pageIdx = this.pages.findIndex((page) => page.pageId === this.page.pageId);
      return pageIdx;
    },

    pageNumber() {
      if (this.pageIdx === -1) return 0;
      return this.pageIdx + 1;
    },

    isPageFocused() {
      return this.pageNumber === this.focusedPage;
    },

    isPageElementFocused() {
      if (!this.pageElementHeight) return false;
      const halfHeight = this.pageElementHeight / 2;
      const halfScreen = this.documentClientHeight / 2;
      const delta = this.pageElementHeight >= halfScreen ? halfScreen : halfHeight;
      const threshold = this.documentScrollTop + delta;
      return this.pageElementTop < threshold && this.pageElementBottom >= threshold;
    },

    pageElementBottom() {
      return this.pageElementTop + this.pageElementHeight;
    },

    showPageBottomActions() {
      return this.showPageActions;
    },
  },

  watch: {
    page(_newPage, oldPage) {
      this.destroyPage(oldPage.pdfjsPage);
    },
    isPageElementFocused(isFocused) {
      if (isFocused) this.focusPage();
    },
    documentScrollLeft: 'updateElementBounds',
    documentScrollTop: 'updateElementBounds',
    documentClientHeight: 'updateElementBounds',
  },

  methods: {
    focusPage() {
      if (this.isPageFocused) return;
      this.$emit('page-focus', this.pageNumber);
    },

    getPageNaturalViewport() {
      const { pdfjsPage } = this.page;
      const viewport = pdfjsPage.getViewport({ scale: 1 })
        .viewBox
        .map(n => n / OUTPUT_DPI * INPUT_DPI);

      return {
        w: viewport[2],
        h: viewport[3],
      };
    },

    getPageScale() {
      let {
        width,
        height,
      } = this.actualSizeViewport;
      let {
        w,
        h,
      } = this.docNaturalDimensions;
      return {
        x: parseFloat((width / w).toFixed(4)),
        y: parseFloat((height / h).toFixed(4)),
      };
    },

    async renderPage() {
      if (this.renderTask) return;

      const { viewport } = this;

      const canvasElem = this.$refs.canvas;
      const canvasContext = canvasElem.getContext('2d');
      const renderContext = {
        canvasContext,
        viewport,
      };

      try {
        const { pdfjsPage } = this.page;
        this.renderTask = pdfjsPage.render(renderContext);
        await this.renderTask.promise;

        const viewport = this.getPageNaturalViewport();

        // save in store for future converting
        this.pagesHeight[this.pageNumber - 1] = viewport.h;
        this.pagesWidth[this.pageNumber - 1] = viewport.w;

        this.$emit('page-rendered', this.page);
      } catch (e) {
        this.destroyRenderTask();

        this.$emit('page-error', this.page);
      }
    },

    destroyPage(page) {
      if (page) page.pdfjsPage.cleanup();
      this.destroyRenderTask();
    },

    destroyRenderTask() {
      if (!this.renderTask) return;
      this.renderTask.cancel();
      delete this.renderTask;
    },

    setInitialViewport() {
      const { pdfjsPage } = this.page;
      this.viewport = pdfjsPage.getViewport({ scale: this.outputScale });
    },

    updateElementBounds() {
      const {
        page,
        pageContainer,
      } = this.$refs;
      if (!page || !pageContainer) return;

      const pageElem = page;
      const {
        offsetTop,
        offsetHeight,
      } = pageElem;
      this.pageElementTop = offsetTop;
      this.pageElementHeight = offsetHeight;

      const {
        width,
        height,
      } = this.pageActualSizes;

      this.scrollPosition = {
        h: height,
        w: width,
        x: this.documentScrollLeft,
        y: this.documentScrollTop,
      };
    },

    onPageMouseEnter(event) {
      this.$emit('page-mouse-enter', event);
    },

    onPageMouseLeave(event) {
      this.$emit('page-mouse-leave', event);
    },

    onPageLayerDrop(event) {
      // ToDo implement it with toolbar drag'n'drop
      const params = {
        scale: this.scale,
        outputScale: this.outputScale,
        actualPageScale: this.actualPageScale,
        pageId: this.pageId,
        pageSizes: this.pageSizes,
        pageActualSizes: this.pageActualSizes,
      };

      //this.$emit('page-layer-drop', { event, params });
    },

    onDraggableAnnotationDragStop({
      annotation,
      top,
      left,
    }) {
      const w = annotation.x2 - annotation.x1;
      const h = annotation.y2 - annotation.y1;
      annotation.x1 = parseFloat((left / this.pageScale.x).toFixed(4));
      annotation.y1 = parseFloat((top / this.pageScale.y).toFixed(4));
      annotation.x2 = annotation.x1 + w;
      annotation.y2 = annotation.y1 + h;
    },

    onDraggableAnnotationResizeStop({
      annotation,
      top,
      left,
      width,
      height,
    }) {
      annotation.x1 = parseFloat((left / this.pageScale.x).toFixed(4));
      annotation.y1 = parseFloat((top / this.pageScale.y).toFixed(4));
      annotation.x2 = parseFloat(((left + width) / this.pageScale.x).toFixed(4));
      annotation.y2 = parseFloat(((top + height) / this.pageScale.x).toFixed(4));
    },

    onPageInsert() {
      this.$emit('page-insert', this.page.pageId);
    },

    onPageRemove() {
      this.$emit('page-remove', this.page.pageId);
    },

    onFileDownload() {
      this.$emit('file-download', this.page.pageId);
    },

    async renderPageWrapper() {
      await this.renderPage();
    },

    getAiSuggestionIndex(annotation) {
      return this.suggestionsList.findIndex(s => s.unique_box_id === annotation.unique_box_id);
    },

    getItemComponentProps(annotation) {
      const inputHeight = (annotation.y2 - annotation.y1) * this.pageScale.y;
      const propsList = {
        text: {
          type: inputHeight > (this.textInputMaxHeight * this.actualPageScale) ? 'textarea' : 'text',
          class: 'hrbr-draggable-annotation__input',
          placeholder: annotation.name,
          value: annotation.value,
          autofocus: true,
        },
        checkbox: {
          class: 'hrbr-draggable-annotation__checkbox',
          value: annotation.value === 'On',
          autofocus: true,
        },
        signature: {
          annotation: annotation,
          value: annotation.value,
        },
        image: {
          annotation: annotation,
          value: annotation.value,
        },
        date: {
          value: annotation.value,
        }
      };

      return propsList[annotation.field_type];
    },

    getItemComponentEvents(annotation) {
      const eventsList = {
        text: {
          input: (value) => {
            annotation.value = value;
            annotation.edited = true;
          },
          focus: () => {
            annotation.active = true;
            this.setFocusedField(annotation);
          },
          blur: () => {
            annotation.active = false;
            if (!annotation.value && annotation.addedByClick) {
              this.filterEmptyClickAddedInputs(annotation.unique_box_id);
            }
          },
        },
        checkbox: {
          input: (value) => {
            annotation.value = value ? 'x' : '';
            annotation.edited = true;
          },
        },
        signature: {
          change: (value) => {
            annotation.value = value;
            annotation.edited = true;
          },
        },
        image: {
          change: (value) => {
            annotation.value = value;
            annotation.edited = true;
          },
          clicked: () => {
            annotation.locked = false;
          },
        },
        date: {
          change: (value) => {
            annotation.value = value;
            annotation.edited = true;
          },
        }
      };

      return eventsList[annotation.field_type];
    },

    getItemComponent(annotation) {
      const type = annotation.field_type;
      const componentsMap = {
        text: 'b-input',
        checkbox: 'b-checkbox',
        signature: HrbrAnnotationSignature,
        image: HrbrAnnotationImage,
        date: HrbrAnnotationDate,
      };

      const props = this.getItemComponentProps(annotation);
      const events = this.getItemComponentEvents(annotation);
      return {
        component: componentsMap[type],
        props,
        events,
      };
    },

    removeAnnotation(id) {
      const annotationIndex = this.suggestionsList.findIndex(item => item.unique_box_id === id);
      const customInputIndex = this.addedInputsList.findIndex(item => item.unique_box_id === id);
      annotationIndex > -1 && this.suggestionsList.splice(annotationIndex, 1);
      customInputIndex > -1 && this.addedInputsList.splice(customInputIndex, 1);
    },

    onDeleteKeydown(annotation) {
      if (annotation.field_type === 'checkbox') {
        this.removeAnnotation(annotation.unique_box_id);
        return;
      }
      if (annotation.active) return;
      this.removeAnnotation(annotation.unique_box_id);
    },

    changeFieldType(annotation, value) {
      if (annotation.field_type === value) return;
      // Resize fields
      const {
        checkbox,
        image,
      } = MAGIC_ANNOTATIONS_SIZES;
      if (value === 'checkbox') {
        annotation.x2 = annotation.x1 + checkbox.w;
        annotation.y2 = annotation.y1 + checkbox.h;
      }
      if (value === 'image') {
        annotation.x2 = annotation.x1 + image.w;
        annotation.y2 = annotation.y1 + image.h;
      }
      annotation.field_type = value;
      annotation.value = null;
      annotation.addedByClick = false;
    },
    setFirstAnnotationRef(el, annotation) {
      // Find the very first annotation to be able to scroll
      const index = this.magicAnnotationsStore.getFirstPageSuggestions.findIndex(item => item.unique_box_id === annotation.unique_box_id);
      if (index !== 0 || this.firstRefSet) return;
      if (el) {
        this.firstAnnotationRef = el.$el;
        this.firstRefSet = true;
      }
    },

    async onPageClick(evt) {
      if (evt.target !== evt.currentTarget) return;
      const xOffset = 10;
      const yOffset = 40;
      const inputId = this.magicAnnotationsStore.addInput({
        type: 'text',
        value: null,
        pageNum: this.pageNumber - 1,
        x: (evt.offsetX / this.pageScale.x) - xOffset,
        y: (evt.offsetY / this.pageScale.y) - yOffset,
        addedByClick: true,
      });

      // Have to focus element so User could start typing immediately
      await this.$nextTick();
      document.querySelector(`[data-id=${inputId}]`)
        .focus();
    },

    filterEmptyClickAddedInputs(id) {
      this.addedInputsList = this.addedInputsList.filter(input => input.unique_box_id !== id);
    },

    getContainerClassName(annotation) {
      return `
        hrbr-draggable-annotation${this.pageMode === 'preview' ? ' fullfilled' : ''}${annotation.animated ? ' animated' : ''}${annotation.animation_visible ? ' animation-in-progress' : ''}${annotation.addedByClick ? ' added-by-click' : ''}${!annotation.value ? ' empty' : ''}${annotation.field_type === 'checkbox' && annotation.value ? ' checked' : ''}
      `;
    },

    getAnnotationVars(annotation) {
      const initialScaledFontSize = 32;
      const inputInitialScale = 4;
      // Need these calculations for proper scale on mobile version
      if (['image', 'checkbox'].includes(annotation.field_type) || !this.harbourStore.isMobileView) return {};
      const annotationVerticalPadding = (annotation.y2 - annotation.y1) * this.pageScale.y * this.verticalPaddingPercentage * 2;
      return {
        '--input-height': `${((annotation.y2 - annotation.y1) * this.pageScale.y - annotationVerticalPadding) * inputInitialScale}px`,
        '--input-width': `${((annotation.x2 - annotation.x1) * this.pageScale.x) * inputInitialScale}px`,
        '--font-size': `${initialScaledFontSize * this.scale}px`,
      };
    },

    getInputWidth(annotation) {
      // Need these calculations for proper scale on mobile version
      let defaultWidth = this.harbourStore.isMobileView ? '400%' : '100%';
      defaultWidth = ['image', 'checkbox'].includes(annotation.field_type) ? '100%' : defaultWidth;
      return annotation.active && !['image', 'checkbox'].includes(annotation.field_type) ? `calc(${defaultWidth} - ${this.actualPageScale * this.typeToggleMinWidth}px)` : defaultWidth;
    },

    onEscClicked(evt) {
      evt.target.blur();
    },

    onTabClicked(evt) {
      const id = evt.target.id || evt.target.dataset.id;
      const annotation = this.pageSuggestions.find(s => s.unique_box_id === id);
      const annotationIndex = this.pageSuggestions.findIndex(s => s.unique_box_id === id);
      {
        if (annotationIndex > -1 && this.pageSuggestions[annotationIndex + 1]) this.pageSuggestions[annotationIndex + 1].active = true;
        if (annotation) annotation.active = false;
      }
    },

    setFocusedField(annotation) {
      const focusedIndex = this.suggestionsList.findIndex(s => s.unique_box_id === annotation.unique_box_id);
      if (focusedIndex > -1) this.focusedSuggestionIndex = focusedIndex + 1;
    },

    getTypeDropdownPosition(annotation) {
      const horizontalPosition = this.docNaturalDimensions.w - annotation.x2 / this.pageScale.x < 130 ? 'r' : 'l';
      const verticalPosition = this.docNaturalDimensions.h - annotation.y2 / this.pageScale.y < 180 ? 't' : 'b';
      return `${horizontalPosition} ${verticalPosition}`;
    },
  },

  created() {
    this.setInitialViewport();
  },

  mounted() {
    this.updateElementBounds();
    this.renderPageWrapper();

    const scale = this.getPageScale();
    this.pagesScale[this.pageNumber - 1] = scale;
  },

  beforeDestroy() {
    this.destroyPage(this.page);
  },
};
</script>

<template>
  <div
    class="hrbr-pdf-viewer-page-container"
    ref="pageContainer">
    <div
      class="hrbr-pdf-viewer-page"
      :data-page-id="page.pageId"
      :data-page-number="pageNumber"
      :data-actual-scale="actualPageScale"
      :style="pageStyle"
      data-pdf-page
      ref="page"
      @mouseenter="onPageMouseEnter"
      @mouseleave="onPageMouseLeave"
      @keydown.esc="onEscClicked"
    >

      <div class="hrbr-pdf-viewer-page__canvas-wrapper" :style="pageStyle">
        <canvas
          class="hrbr-pdf-viewer-page__canvas"
          v-bind="canvasAttrs"
          :style="pageStyle"
          ref="canvas">
        </canvas>
      </div>

      <div
        :class="[
          'hrbr-pdf-viewer-page__edit-layer',
          pageMode,
          harbourStore.isMobileView && 'mobile-view'
        ]"
        :style="pageStyle"
        ref="editLayer"
        data-edit-layer
        @drop="onPageLayerDrop"
        @dragover.prevent
        @click="onPageClick"
        @keydown.tab="onTabClicked"
      >
        <template
          v-for="(annotation, index) in pageSuggestions"
        >
          <VueDraggableResizable
            :class-name="getContainerClassName(annotation)"
            class-name-dragging="hrbr-draggable-annotation--dragging"
            class-name-resizing="hrbr-draggable-annotation--resizing"
            class-name-active="hrbr-draggable-annotation--active"
            class-name-handle="hrbr-draggable-annotation__handle"
            :style="{
              borderWidth: actualPageScale <= 1 ? `${2 * actualPageScale}px` : '1px',
              borderRadius: actualPageScale <= 1 ? `${2 * actualPageScale}px` : '2px'
            }"
            :w="((annotation.x2 - annotation.x1) * pageScale.x)"
            :h="((annotation.y2 - annotation.y1) * pageScale.y)"
            :x="(annotation.x1 * pageScale.x)"
            :y="(annotation.y1 * pageScale.y)"
            :handles="['br', 'bl', 'tl', 'tr']"
            :active.sync="annotation.active"
            :key="annotation.unique_box_id + '_' + scale"
            :ref="val => setFirstAnnotationRef(val, annotation)"
            drag-cancel=".input"
            draggable
            resizable
            parent
            @click.stop
            @mouseover.stop="() => annotation.active = true"
            @mouseleave.stop="() => annotation.active = false"
            @dragging="() => annotation.locked = true"
            @resizing="() => annotation.locked = true"
            @activated="() => setFocusedField(annotation)"
            @dragstop="
                (left, top) =>
                  onDraggableAnnotationDragStop({
                    annotation,
                    left,
                    top,
                  })
              "
            @resizestop="
                (left, top, width, height) =>
                  onDraggableAnnotationResizeStop({
                    annotation,
                    left,
                    top,
                    width,
                    height,
                  })
              "
          >
            <div
              :class="[
                'hrbr-draggable-annotation__content',
                annotation.field_type === 'checkbox' && 'checkbox-content',
                annotation.field_type === 'image' && annotation.value && 'has-image',
                harbourStore.isMobileView && 'mobile-view'
              ]"
              :style="{ padding: annotation.field_type === 'checkbox' ? '0' : `${((annotation.y2 - annotation.y1) * pageScale.y) * verticalPaddingPercentage}px ${((annotation.x2 - annotation.x1) * pageScale.x * horizontalPaddingPercentage)}px` }"
              tabindex="-1"
              @keydown.delete="onDeleteKeydown(annotation)"
            >
              <component
                :is="getItemComponent(annotation).component"
                :data-id="annotation.unique_box_id"
                :input-id="annotation.unique_box_id"
                :style="{
                  ...getAnnotationVars(annotation),
                  width: getInputWidth(annotation),
                  fontSize: Math.round(fontSizeMin * actualPageScale) + 'px',
                }"
                v-bind="getItemComponent(annotation).props"
                v-on="getItemComponent(annotation).events"
              >
              </component>
              <hrbr-type-switcher
                v-if="annotation.field_type !== 'checkbox'"
                class="annotation-type-switcher"
                :style="{
                  transform: `scale(${actualPageScale})`,
                  maxHeight: `${((annotation.y2 - annotation.y1) * pageScale.y)}px`
                }"
                :dropdown-position="getTypeDropdownPosition(annotation)"
                :max-height="((annotation.y2 - annotation.y1) * pageScale.y) / actualPageScale"
                :field-type="annotation.field_type"
                @change="(value) => changeFieldType(annotation, value)"
              />

              <div
                v-if="annotation.field_type !== 'checkbox'"
                class="hrbr-draggable-annotation__remove"
                :style="{
                  transform: `scale(${actualPageScale})`,
                }"
                @click.stop="removeAnnotation(annotation.unique_box_id)"
              >
                <i class="fa-regular fa-xmark"></i>
              </div>
            </div>
          </VueDraggableResizable>
        </template>
      </div>
    </div>

    <div class="hrbr-pdf-viewer-page__page-bottom">
      <div class="hrbr-pdf-viewer-page__page-actions">
        <div class="hrbr-pdf-viewer-page__page-number">Page {{ pageIdx + 1 }}</div>
        <template v-if="showPageBottomActions">
          <div
            class="hrbr-pdf-viewer-page__insert-pages"
            @click="onPageInsert">
            <i class="fal fa-file-plus"></i>
          </div>
          <b-dropdown
            class="hrbr-pdf-viewer-page__dropdown-actions"
            :triggers="['click']"
            :mobile-modal="false"
            :append-to-body="true">
            <b-icon
              class="hrbr-pdf-viewer-page__dropdown-actions-trigger"
              icon="ellipsis-v"
              pack="fal"
              size="is-small"
              slot="trigger"></b-icon>
            <b-dropdown-item @click="onFileDownload">
              <b-icon icon="arrow-circle-down" pack="fal"></b-icon>
              <span>Download this page</span>
            </b-dropdown-item>
            <b-dropdown-item @click="onPageRemove">
              <b-icon pack="fal" icon="minus-circle"></b-icon>
              <span>Remove this page</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-pdf-viewer-page-container {
  --input-height: 100%;
  --input-width: 100%;
  --font-size: 16px;

  &--custom-overlay {
    position: relative;

    .hrbr-pdf-viewer-page {
      border: 0;
    }
  }
}

.hrbr-pdf-viewer-page {
  width: 816px; /* 100% */
  height: 1056px; /* auto */
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  border: 9px solid transparent;
  background-clip: content-box;
  overflow: visible;
  box-sizing: content-box;

  &:last-child {
    margin-bottom: 0;
  }

  &__canvas-wrapper {
    overflow: hidden;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  }

  &__canvas {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
  }

  &__text-layer {
    position: absolute;
    text-align: initial;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.2;
    line-height: 1;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    forced-color-adjust: none;

    span,
    br {
      color: transparent;
      position: absolute;
      white-space: pre;
      cursor: text;
      transform-origin: 0% 0%;
    }

    span.markedContent {
      top: 0;
      height: 0;
    }

    ::selection {
      background: var(--hrbr-primary-color-active);
    }

    br::selection {
      background: transparent;
    }

    .highlight {
      margin: -1px;
      padding: 1px;
      background-color: rgba(180, 0, 170, 1);
      border-radius: 4px;
    }

    .highlight.appended {
      position: initial;
    }
  }

  &__edit-layer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    cursor: text;

    &--not-editable {
      .hrbr-draggable-annotation {
        cursor: default;
      }
    }

    &.preview {
      pointer-events: none;
      cursor: default;
    }

    &.mobile-view {
      .hrbr-draggable-annotation {
        :deep(&__handle) {
          width: 3px;
          height: 3px;
        }

        :deep(&__handle-br) {
          bottom: -2px;
          right: -2px;
        }

        :deep(&__handle-bl) {
          bottom: -2px;
          left: -2px;
        }

        :deep(&__handle-tl) {
          top: -2px;
          left: -2px;
        }

        :deep(&__handle-tr) {
          top: -2px;
          right: -2px;
        }
      }
    }
  }

  &__page-bottom {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }

  &__page-actions {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__page-number {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    line-height: 1.2;
  }

  &__insert-pages {
    font-size: 12px;
    color: #02020299;
    display: inline-flex;
    cursor: pointer;

    &:hover {
      color: #222222;
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__dropdown-actions-trigger {
    font-size: 16px;
    color: #02020299;
    cursor: pointer;

    &:hover {
      color: #222222;
    }
  }
}

.hrbr-draggable-annotation {
  position: absolute;
  border: 1px solid transparent;
  border-bottom-color: #B015B3;
  border-radius: 2px;
  box-sizing: content-box;
  cursor: grab !important;
  background: #D9D8E9;
  z-index: 0;

  &:not(.animation-in-progress):before {
    display: none;
  }

  &.animated {
    background: transparent;
    border-color: #fff;
    border-bottom-color: transparent;
  }

  &.animation-in-progress {
    background: linear-gradient(to right, #D9D8E9 50%, transparent 0);
    background-size: 200% 100%;
    background-position: right;
    animation: makeItfadeIn .4s .4s forwards;
    border-color: #fff;
    border-bottom-color: transparent;
    border-radius: 2px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: -1px;
      width: 0;
      border-bottom: 1px solid #B015B3;
      animation: border_anim .4s .4s forwards;
    }
  }

  .annotation-type-switcher {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &:hover {
    border-color: #B015B3;
    z-index: 100 !important;

    .annotation-type-switcher,
    .hrbr-draggable-annotation__remove {
      display: flex;
    }
  }

  &.added-by-click {
    &.empty {
      opacity: .7;

      :deep(.hrbr-draggable-annotation__handle) {
        display: none !important;
      }

      .annotation-type-switcher,
      .hrbr-draggable-annotation__remove {
        display: none;
      }
    }
  }

  &.checked {
    border-color: #B015B3 !important;
  }

  &.fullfilled:not(.hrbr-draggable-annotation--active):not(&:hover) {
    border-color: transparent;
    background-color: transparent;

    ::placeholder {
      color: transparent;
    }

    :deep(.hrbr-draggable-annotation__input .input),
    :deep(.hrbr-draggable-annotation__input .textarea) {
      color: #333;
    }

    :deep(.hrbr-draggable-annotation__checkbox .check) {
      border-color: #333;
      border-width: 1px;
      border-radius: 2px;
    }

    .hrbr-draggable-annotation__checkbox:deep(input[type=checkbox]:checked + .check) {
      background-color: #333;
      border-width: 1px;
    }

    :deep(.annotation-signature__edit) {
      display: none;
    }
  }

  &--dragging {
    border-color: #B015B3;

    .hrbr-draggable-annotation__holder {
      display: none;
    }
  }

  &--active {
    border-color: #B015B3;
    z-index: 100 !important;

    .annotation-type-switcher,
    .hrbr-draggable-annotation__remove {
      display: flex;
    }
  }

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(.checkbox-content) {
      padding: 1%;
    }

    &.has-image {
      padding: 0;
    }

    &.mobile-view {
      .hrbr-draggable-annotation__input {
        transform: scale(0.25);
        transform-origin: top left;

        :deep(.input),
        :deep(.textarea) {
          height: var(--input-height);
          width: var(--input-width);
          font-size: var(--font-size);
        }
      }
    }
  }

  &__remove {
    display: none;
    position: absolute;
    content: '';
    top: -10px;
    right: -10px;
    align-items: center;
    justify-content: center;
    width: 18px;
    height: 18px;
    color: #fff;
    font-size: 12px;
    border-radius: 50%;
    background-color: #B015B3;
    z-index: 10;
    cursor: pointer;
  }

  :deep(&__handle) {
    position: absolute;
    width: 6px;
    height: 6px;
    background: #B015B3;
    border-radius: 50%;
    transition: all 0.3s ease;
    box-sizing: border-box;
    cursor: pointer;
  }

  :deep(&__handle-br) {
    bottom: -4px;
    right: -4px;
    cursor: se-resize;
  }

  :deep(&__handle-bl) {
    bottom: -4px;
    left: -4px;
    cursor: sw-resize
  }

  :deep(&__handle-tl) {
    top: -4px;
    left: -4px;
    cursor: nw-resize;
  }

  :deep(&__handle-tr) {
    top: -4px;
    right: -4px;
    cursor: ne-resize;
  }

  &__holder {
    display: flex;
    height: 100%;
    user-select: none;
    pointer-events: none;
    overflow: hidden;
    box-sizing: border-box;

    &--text,
    &--checkbox {
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__input {
    height: 100%;

    :deep(.input),
    :deep(.textarea) {
      height: 100%;
      padding: 0 1px;
      font-size: inherit;
      color: #B015B3;
      font-weight: 600;
      border-radius: 0;
      border: none;
      box-shadow: none;
      background-color: transparent;
      resize: none;

      &::placeholder {
        font-weight: 600;
        color: #999;
      }
    }
  }

  &__checkbox {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 !important;

    :deep(input[type=checkbox]:not(:disabled) + .check) {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      border-color: transparent;
      border-width: 1px;
    }

    :deep(input[type=checkbox]:focus:checked + .check) {
      box-shadow: none;
    }

    :deep(input[type=checkbox]:checked + .check) {
      background-color: #B015B3;
      border-width: 1px;
    }

    &:hover {
      :deep(input[type=checkbox]:not(:disabled) + .check) {
        border-color: transparent;
      }
    }
  }
}


@media screen and (max-width: 768px) {
  .hrbr-draggable-annotation {
    &__content {
      &:not(.checkbox-content) {
        padding: 0 1%;
      }
    }
  }
}

@keyframes makeItfadeIn {
  100% {
    background-position: left;
  }
}

@keyframes border_anim {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}
</style>
