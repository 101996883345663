<script setup>
import { ref } from 'vue';
import { readFileAsDataUrl } from '@/utils/helpers/functions.js';
const props = defineProps({
  value: {
    type: String,
  },
  annotation: {
    type: Object,
  }
});
const emit = defineEmits(['change', 'clicked', 'focus']);
const fileInput = ref(null);

const onInputClick = (evt) => {
  // need locked prop to prevent file explorer from opening when resize or drag
  if (props.annotation.locked) {
    evt.preventDefault();
    emit('clicked');
    return;
  }

  evt.target.click();
}

const getImage = async (evt) => {
  const file = evt.target.files[0];
  const base64 = await readFileAsDataUrl(file);
  emit('change', base64);
};

const onKeyDown = () => {
  fileInput.value.click();
};
</script>

<template>
  <div
    class="annotation-image"
    tabindex="0"
    @keydown.enter="onKeyDown"
    @focus="emit('focus')"
  >
    <label>
      <span v-if="!value">Click to add image</span>
      <img v-else :src="value" alt="image">
      <input
        type="file"
        ref="fileInput"
        accept="image/jpeg, image/png"
        hidden
        @change="evt => getImage(evt)"
        @click="onInputClick"
      />
    </label>
  </div>
</template>

<style lang="postcss" scoped>
.annotation-image {
  width: 100%;
  height: 100%;
  text-align: center;
  font-weight: 600;
  color: #999;
  outline: none;
  img {
    width: auto;
    max-height: 100%;
  }
  label {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}
</style>
