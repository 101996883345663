<script>
import HrbrPdfViewerPage from './HrbrPdfViewerPage.vue';
import HrbrPdfViewerPageMagicAnnotations from './HrbrPdfViewerPageMagicAnnotations.vue';
import throttle from '@/utils/throttle';
import { storeToRefs } from 'pinia';
import { useMagicAnnotationsStore } from '@/stores/magic-annotations-store.js';

const SCROLLBAR_PADDING = 40;
const VERTICAL_PADDING = 5;

export default {
  name: 'HrbrPdfViewerDocument',

  components: {
    HrbrPdfViewerPage,
    HrbrPdfViewerPageMagicAnnotations
  },

  directives: {
    scroll: {
      inserted(el, binding) {
        const callback = binding.value;
        if (binding.modifiers.immediate) {
          callback();
        }
        const throttledScroll = throttle(callback, 300);
        el.addEventListener('scroll', throttledScroll, true);
      },
    },
  },

  props: {
    pdf: {
      type: Object,
    },
    pages: {
      type: Array,
      required: true,
    },
    pageCount: {
      type: Number,
      default: 0,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    scale: {
      type: Number,
    },
    outputScale: {
      type: Number,
    },
    fit: {
      type: String,
    },
    isTextLayer: {
      type: Boolean,
    },
    mode: {
      type: String,
    },
    draggableAnnotations: {
      type: Array,
    },
    draggableInputs: {
      type: Array,
    },
    isEditingEnabled: {
      type: Boolean,
      default: false,
    },
    showPageActions: {
      type: Boolean,
    },
    customPageOverlay: {
      type: Object,
      required: false,
    }
  },

  setup() {
    const magicAnnotationsStore = useMagicAnnotationsStore();
    const { isOnlySigner, documentRef } = storeToRefs(magicAnnotationsStore);

    return {
      isOnlySigner,
      documentRef
    };
  },

  data() {
    return {
      focusedPage: null,
      documentScrollTop: 0,
      documentScrollLeft: 0,
      documentClientHeight: 0,
      isResizeAllowed: true,
    };
  },

  computed: {
    defaultViewport() {
      if (!this.pages.length) {
        return { width: 0, height: 0 };
      }
      const [page] = this.pages; // first page
      return page.pdfjsPage.getViewport({ scale: 1 });
    },
  },

  watch: {
    currentPage(page) {
      this.setFocusedPage(page);
    },

    fit(fit) {
      this.fitPage(fit);
    },
  },

  methods: {
    updateScale(scale) {
      if (!scale) return;
      this.$emit('scale-change', scale);
    },

    updateFit(fit) {
      this.$emit('fit-change', fit);
    },

    onPageFocused(pageNumber) {
      this.$emit('page-focus', pageNumber);
    },

    onPageRendered(page) {
      this.$emit('page-rendered', page);
      this.documentRef = this.$refs.document;
    },

    onPageError(page) {
      this.$emit('page-error', page);
    },

    onPageMouseEnter(event) {
      this.$emit('page-mouse-enter', event);
    },

    onPageMouseLeave(event) {
      this.$emit('page-mouse-leave', event);
    },

    onPageLayerDrop(payload) {
      this.$emit('page-layer-drop', payload);
    },

    onPageInsert(pageId) {
      this.$emit('page-insert', pageId);
    },

    onPageRemove(pageId) {
      this.$emit('page-remove', pageId);
    },

    onFileDownload(pageId) {
      this.$emit('file-download', pageId);
    },

    onDraggableAnnotationActivated(payload) {
      this.$emit('draggable-annotation-activated', payload);
    },

    onPagesLoaded() {
      this.fitWidth();
      this.updateFit('page-width');
    },

    getPageWidthScale() {
      const { defaultViewport, $el } = this;
      if (!defaultViewport.width) return 0;
      return ($el.clientWidth - SCROLLBAR_PADDING) / defaultViewport.width;
    },

    getPageHeightScale() {
      const { defaultViewport, $el } = this;
      if (!defaultViewport.height) return 0;
      return ($el.clientHeight - VERTICAL_PADDING) / defaultViewport.height;
    },

    fitWidth() {
      const scale = this.getPageWidthScale();
      this.updateScale(scale);
    },

    fitAuto() {
      const scale = Math.min(this.getPageWidthScale(), this.getPageHeightScale());
      this.updateScale(scale);
    },

    fitPage(fit) {
      if (!fit) return;

      if (fit === 'page-width') {
        this.fitWidth();
        return;
      }
      if (fit === 'page-auto') {
        this.fitAuto();
      }
    },

    setFocusedPage(page) {
      this.focusedPage = page;
    },

    setElementFocus(element) {
      this.$emit('set-element-focus', element)
    },

    setIsResizeAllowed(isAllowed) {
      this.isResizeAllowed = isAllowed;
    },

    resizeDocument() {
      if (!this.fit || !this.isResizeAllowed) return;
      this.fitPage(this.fit);
    },

    updateScrollBounds() {
      const { document } = this.$refs;
      if (!document) return;

      const { scrollTop, clientHeight, scrollLeft } = document;
      this.documentScrollTop = scrollTop;
      this.documentScrollLeft = scrollLeft
      this.documentClientHeight = clientHeight;
    },

    onCheckboxResize(annotation) {
      this.$emit('checkbox-resize', annotation);
    },

    onCheckboxDrag(annotation) {
      this.$emit('checkbox-drag', annotation);
    },
    getPageComponentData(page) {
      if (this.isOnlySigner) {
        return {
          name: 'HrbrPdfViewerPageMagicAnnotations',
          props: {
            page,
            pages: this.pages,
            scale: this.scale,
            outputScale: this.outputScale,
            focusedPage: this.focusedPage,
            mode: this.mode,
            isTextLayer: this.isTextLayer,
            documentScrollTop: this.documentScrollTop,
            documentScrollLeft: this.documentScrollLeft,
            documentClientHeight: this.documentClientHeight,
            draggableAnnotations: this.draggableAnnotations,
            draggableInputs: this.draggableInputs,
            isEditingEnabled: this.isEditingEnabled,
            showPageActions: false,
          },
          events: {
            'page-focus': this.onPageFocused,
            'page-rendered': this.onPageRendered,
            'page-error': this.onPageError,
            'page-mouse-enter': this.onPageMouseEnter,
            'page-mouse-leave': this.onPageMouseLeave,
            'page-layer-drop': this.onPageLayerDrop,
            'page-insert': this.onPageInsert,
            'page-remove': this.onPageRemove,
            'file-download': this.onFileDownload,
            'draggable-annotation-activated': this.onDraggableAnnotationActivated,
            'checkbox-resize': this.onCheckboxResize,
            'checkbox-drag': this.onCheckboxDrag,
            'set-element-focus': this.setElementFocus
          },
        };
      } else {
        return {
          name: 'HrbrPdfViewerPage',
          props: {
            page,
            pages: this.pages,
            scale: this.scale,
            outputScale: this.outputScale,
            focusedPage: this.focusedPage,
            mode: this.mode,
            isTextLayer: this.isTextLayer,
            documentScrollTop: this.documentScrollTop,
            documentClientHeight: this.documentClientHeight,
            draggableAnnotations: this.draggableAnnotations,
            draggableInputs: this.draggableInputs,
            isEditingEnabled: this.isEditingEnabled,
            showPageActions: this.showPageActions,
            customPageOverlay: this.customPageOverlay
          },
          events: {
            'page-focus': this.onPageFocused,
            'page-rendered': this.onPageRendered,
            'page-error': this.onPageError,
            'page-mouse-enter': this.onPageMouseEnter,
            'page-mouse-leave': this.onPageMouseLeave,
            'page-layer-drop': this.onPageLayerDrop,
            'page-insert': this.onPageInsert,
            'page-remove': this.onPageRemove,
            'file-download': this.onFileDownload,
            'draggable-annotation-activated': this.onDraggableAnnotationActivated,
            'checkbox-resize': this.onCheckboxResize,
            'checkbox-drag': this.onCheckboxDrag,
            'set-element-focus': this.setElementFocus
          },
        };
      }
    }
  },

  // dynamic component hook
  activated() {
    this.setIsResizeAllowed(true);
    this.fitPage(this.fit);
  },

  // dynamic component hook
  deactivated() {
    this.setIsResizeAllowed(false);
  },

  created() {
    this.throttledResizeDocument = throttle(this.resizeDocument, 300);
    this.$parent.$on('pages-loaded', this.onPagesLoaded);
    this.setFocusedPage(this.currentPage);
  },

  mounted() {
    window.addEventListener('resize', this.throttledResizeDocument);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.throttledResizeDocument);
  },
};
</script>

<template>
  <div class="hrbr-pdf-viewer-document" v-scroll.immediate="updateScrollBounds" ref="document">
    <div class="hrbr-pdf-viewer-document__wrapper" ref="documentWrapper">
      <component
        v-for="page in pages"
        :key="page.pageId"
        :is="getPageComponentData(page).name"
        v-bind="getPageComponentData(page).props"
        v-on="getPageComponentData(page).events"
      />
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-pdf-viewer-document {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  overflow: auto;
  background: rgb(247, 249, 252);

  &__wrapper {
    padding-bottom: 10px;
  }
}
</style>
