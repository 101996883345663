<script>
import VueDraggableResizable from 'vue-draggable-resizable';
import { floor } from '@/utils/helpers/functions';
import { subscribeTo, unsubscribeFrom } from '@/utils/bus';

// TODO: load from AEv2 config.
const FONT_SIZE_MIN = 7; // pt
const EDIT_LABEL_PROMPT_INPUTS = [
  'TEXTINPUT',
  'HTMLINPUT',
  'YESNOINPUT',
  'DATEINPUT',
  'CHECKBOXINPUT',
  'URLTEXTINPUT',
  'IMAGEINPUT',
  'FILEUPLOADER',
  'DROPDOWNINPUT',
];

export default {
  name: 'HrbrPdfViewerPage',

  components: {
    VueDraggableResizable,
  },

  props: {
    page: {
      type: Object,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
    },
    scale: {
      type: Number,
      required: true,
    },
    outputScale: {
      type: Number,
      required: true,
    },
    focusedPage: {
      type: Number,
      default: null,
    },
    isTextLayer: {
      type: Boolean,
    },
    documentScrollTop: {
      type: Number,
      default: 0,
    },
    documentClientHeight: {
      type: Number,
      default: 0,
    },
    mode: {
      type: String,
    },
    draggableAnnotations: {
      type: Array,
    },
    draggableInputs: {
      type: Array,
    },
    isEditingEnabled: {
      type: Boolean,
      default: false,
    },
    showPageActions: {
      type: Boolean,
    },
    customPageOverlay: {
      type: Object,
      required: false,
    }
  },

  data() {
    return {
      viewport: null,
      pageElementTop: 0,
      pageElementHeight: 0,
      fontSizeMin: FONT_SIZE_MIN,
    };
  },

  inject: {
    getAnnotationIdx: {
      default() {
        return () => 0;
      },
    },
    updateLastEditedAnnotationGeometry: {
      default() {
        return () => null;
      },
    },
    availableFonts: {
      default: () => [],
    },
    inputTiles: {
      default: () => [],
    },
  },

  computed: {
    actualPageScale() {
      return floor(this.scale / this.outputScale, 3);
    },

    actualSizeViewport() {
      return this.viewport.clone({ scale: this.scale });
    },

    pageSizes() {
      let { width, height } = this.viewport;
      [width, height] = [width, height].map((dim) => Math.ceil(dim));
      return { width, height };
    },

    pageActualSizes() {
      let { width, height, scale } = this.actualSizeViewport;
      if (this.hasCustomPageOverlay) {
        return { width: width + (20 * scale), height: height + (20 * scale) };
      }
      [width, height] = [width, height].map((dim) => Math.ceil(dim));
      return { width, height };
    },

    pageStyle() {
      const { width: widthPx, height: heightPx } = this.pageActualSizes;
      const style = {
        width: `${widthPx}px`,
        height: `${heightPx}px`,
      };
      if (this.hasCustomPageOverlay) style.marginBottom = '10px';
      return style;
    },

    canvasAttrs() {
      const { width, height } = this.pageSizes;
      return { width, height };
    },

    pageIdx() {
      const pageIdx = this.pages.findIndex((page) => page.pageId === this.page.pageId);
      return pageIdx;
    },

    pageNumber() {
      if (this.pageIdx === -1) return 0;
      return this.pageIdx + 1;
    },

    isPageFocused() {
      return this.pageNumber === this.focusedPage;
    },

    isPageElementFocused() {
      if (!this.pageElementHeight) return false;
      const halfHeight = this.pageElementHeight / 2;
      const halfScreen = this.documentClientHeight / 2;
      const delta = this.pageElementHeight >= halfScreen ? halfScreen : halfHeight;
      const threshold = this.documentScrollTop + delta;
      return this.pageElementTop < threshold && this.pageElementBottom >= threshold;
    },

    pageElementBottom() {
      return this.pageElementTop + this.pageElementHeight;
    },

    isEditMode() {
      return this.mode === 'edit';
    },

    draggableAnnotationsFilteredByPage() {
      const { pageId } = this.page;
      const annotations = this.draggableAnnotations
        .filter((a) => a.pageid === pageId)
        .sort((a, b) => a.lastinteractiondatetime - b.lastinteractiondatetime);
      return annotations;
    },

    draggableIconAnnotations() {
      return ['SIGNATUREINPUT', 'HTMLINPUT', 'IMAGEINPUT'];
    },

    showPageBottomActions() {
      return this.showPageActions && this.isEditMode;
    },

    hasCustomPageOverlay() {
      return !!this.customPageOverlay;
    },
  },

  watch: {
    page(_newPage, oldPage) {
      this.destroyPage(oldPage.pdfjsPage);
    },
    isPageElementFocused(isFocused) {
      if (isFocused) this.focusPage();
    },
    scale: 'renderTextLayer',
    documentScrollTop: 'updateElementBounds',
    documentClientHeight: 'updateElementBounds',
  },

  methods: {
    focusPage() {
      if (this.isPageFocused) return;
      this.$emit('page-focus', this.pageNumber);
    },

    async renderPage() {
      if (this.renderTask) return;

      const { viewport } = this;

      const canvasElem = this.$refs.canvas;
      const canvasContext = canvasElem.getContext('2d');
      const renderContext = { canvasContext, viewport };

      try {
        const { pdfjsPage } = this.page;
        this.renderTask = pdfjsPage.render(renderContext);
        await this.renderTask.promise;

        this.$emit('page-rendered', this.page);
      } catch (e) {
        this.destroyRenderTask();

        this.$emit('page-error', this.page);
      }
    },

    // TODO: Check method with new pdf.js version (fix if needed)
    async renderTextLayer() {
      if (!this.isTextLayer) return;

      const container = this.$refs.textLayer;
      if (!container) return;

      // clear container
      container.innerHTML = '';

      const viewport = this.actualSizeViewport;

      try {
        const { pdfjsPage } = this.page;
        const textContent = await pdfjsPage.getTextContent();
        const renderContext = {
          textContentSource: textContent,
          container,
          viewport,
          textDivs: [],
        };

        const textRenderTask = this.$pdfjs.renderTextLayer(renderContext);
        await textRenderTask.promise;

        this.$emit('text-layer-rendered', this.page);
      } catch (e) {
        this.$emit('text-layer-error', this.page);
        console.error(e);
      }
    },

    destroyPage(page) {
      if (page) page.pdfjsPage.cleanup();
      this.destroyRenderTask();
    },

    destroyRenderTask() {
      if (!this.renderTask) return;
      this.renderTask.cancel();
      delete this.renderTask;
    },

    setInitialViewport() {
      const { pdfjsPage } = this.page;
      this.viewport = pdfjsPage.getViewport({ scale: this.outputScale });
    },

    updateElementBounds() {
      const { page, pageContainer } = this.$refs;
      if (!page || !pageContainer) return;
      const pageElem = this.hasCustomPageOverlay ? pageContainer : page;
      const { offsetTop, offsetHeight } = pageElem;
      this.pageElementTop = offsetTop;
      this.pageElementHeight = offsetHeight;
    },

    onPageMouseEnter(event) {
      this.$emit('page-mouse-enter', event);
    },

    onPageMouseLeave(event) {
      this.$emit('page-mouse-leave', event);
    },

    onPageLayerDrop(event) {
      const params = {
        scale: this.scale,
        outputScale: this.outputScale,
        actualPageScale: this.actualPageScale,
        pageId: this.page.pageId,
        pageSizes: this.pageSizes,
        pageActualSizes: this.pageActualSizes,
      };

      this.$emit('page-layer-drop', { event, params });
    },

    onInputTouchEnd(touchData) {
      const elemBelow = document.elementFromPoint(touchData.left, touchData.top);
      const targetPage = elemBelow.closest('[data-page-id]');
      const targetPageId = targetPage?.dataset.pageId;

      if (targetPageId !== this.page.pageId) return;

      const pageRect = elemBelow.getBoundingClientRect();

      this.onPageLayerDrop({
        clientX: touchData.left,
        clientY: touchData.top,
        offsetX: touchData.left - pageRect.x,
        offsetY: touchData.top - pageRect.y,
      });
    },

    getDraggableHandles(fieldType) {
      const hasCornerHandles = ['SIGNATUREINPUT', 'HTMLINPUT', 'BANNERINPUT', 'IMAGEINPUT'];

      if (fieldType === 'CHECKBOXINPUT') {
        return ['tr'];
      }
      if (hasCornerHandles.includes(fieldType)) {
        return ['tr', 'br', 'tl', 'bl'];
      }
      return ['mr'];
    },

    onDraggableAnnotationDragging({ id, left: x, top: y }) {
      const annotation = this.getDraggableAnnotationById(id);
      if (!annotation) return;

      const newX = Math.round(x / this.actualPageScale);
      const newY = Math.round(y / this.actualPageScale);

      annotation.lastinteractiondatetime = Date.now();

      // update coords (not scaled)
      this.updateDraggableAnnotationCoords({ id, x: newX, y: newY });
    },

    onDraggableAnnotationResizing({ id, left: x, top: y, width, height, fieldType }) {
      const annotation = this.getDraggableAnnotationById(id);
      if (!annotation) return;

      const newX = Math.round(x / this.actualPageScale);
      const newY = Math.round(y / this.actualPageScale);

      const newWidth = Math.round(width / this.actualPageScale);
      let newHeight = Math.round(height / this.actualPageScale);

      let newFontSize = this.getFontSizeFromBoundingBox({ height: newHeight });

      if (fieldType === 'TEXTINPUT') {
        // avoid rounding error at different scales and keep the original height
        newHeight = annotation.height;
      }
      if (fieldType === 'CHECKBOXINPUT') {
        newFontSize = newWidth;
      }

      annotation.lastinteractiondatetime = Date.now();

      // update coords/dims, font size (not scaled)
      this.updateDraggableAnnotationCoords({ id, x: newX, y: newY });
      this.updateDraggableAnnotationDims({ id, width: newWidth, height: newHeight });

      if (fieldType !== 'TEXTINPUT') {
        this.updateDraggableAnnotationFontSize({ id, fontSize: newFontSize });
      }
    },

    onDraggableAnnotationDragStop({ id, fieldType }) {
      const annotation = this.getDraggableAnnotationById(id);
      if (!annotation) return;

      if (fieldType === 'CHECKBOXINPUT') {
        this.$emit('checkbox-drag', annotation);
      }

      this.updateLastEditedAnnotationGeometryWrapper({
        fieldType,
        x: annotation.x,
        y: annotation.y,
        w: annotation.width,
        h: annotation.height,
      });
    },

    onDraggableAnnotationResizeStop({ id, fieldType }) {
      const annotation = this.getDraggableAnnotationById(id);
      if (!annotation) return;

      if (fieldType === 'CHECKBOXINPUT') {
        this.$emit('checkbox-resize', annotation);
      }

      this.updateLastEditedAnnotationGeometryWrapper({
        fieldType,
        x: annotation.x,
        y: annotation.y,
        w: annotation.width,
        h: annotation.height,
      });
    },

    onDraggableAnnotationDragStartCallback() {
      return this.isEditingEnabled;
    },

    onDraggableAnnotationResizeStartCallback() {
      return this.isEditingEnabled;
    },

    updateDraggableAnnotationCoords({ id, x = null, y = null }) {
      const annotation = this.getDraggableAnnotationById(id);

      if (x !== null) {
        annotation.x = x;
      }
      if (y !== null) {
        annotation.y = y;
      }
    },

    updateDraggableAnnotationDims({ id, width = null, height = null }) {
      const annotation = this.getDraggableAnnotationById(id);

      if (width !== null) {
        annotation.width = width;
      }
      if (height !== null) {
        annotation.height = height;
      }
    },

    updateDraggableAnnotationFontSize({ id, fontSize = null }) {
      const annotation = this.getDraggableAnnotationById(id);

      if (fontSize !== null) {
        annotation.fontsize = fontSize;
      }
    },

    updateLastEditedAnnotationGeometryWrapper({ fieldType, w, h, x, y }) {
      if (fieldType === 'CHECKBOXINPUT') return;

      this.updateLastEditedAnnotationGeometry({
        fieldType,
        w,
        h,
        x,
        y,
        overwrite: true,
      });
    },

    getDraggableAnnotationMinDims(fieldType) {
      let minWidth = 15 * this.outputScale;
      let minHeight = this.fontSizeMin;

      if (fieldType === 'CHECKBOXINPUT') {
        minWidth = 10;
        minHeight = 10;
      }
      return { minWidth, minHeight };
    },

    getDraggableAnnotationById(id) {
      const annotation = this.draggableAnnotations.find((a) => a.id === id);
      if (!annotation) return null;
      return annotation;
    },

    getDraggableAnnotationTextValue(input) {
      if (input.itemdefaultvalue && input.itemfieldtype === 'DATEINPUT') {
        return this.getFormattedDate(input.itemdefaultvalue, input.itemtypeconfig.inputformat);
      }

      if (input.itemdefaultvalue && input.itemfieldtype !== 'YESNOINPUT') {
        return input.itemdefaultvalue;
      }

      const displayLabelFields = EDIT_LABEL_PROMPT_INPUTS;
      if (displayLabelFields.includes(input.itemfieldtype) && input.itemdisplaylabel) {
        return input.itemdisplaylabel;
      }

      const inputTile = this.inputTiles.find((i) => i.inputtiletype === input.itemfieldtype);
      if (inputTile) return inputTile.inputtiletitle;

      return '';
    },

    getFontSizeFromBoundingBox({ height, fontSizeMin = this.fontSizeMin }) {
      const newFontSize = Math.floor(height);
      return Math.max(fontSizeMin, newFontSize);
    },

    onPageInsert() {
      this.$emit('page-insert', this.page.pageId);
    },

    onPageRemove() {
      this.$emit('page-remove', this.page.pageId);
    },

    onFileDownload() {
      this.$emit('file-download', this.page.pageId);
    },

    onDraggableAnnotationActivated({ input }) {
      this.$emit('draggable-annotation-activated', { input });
    },

    // 80 - 50%
    getHexColorWithTransparency(color, code = '80') {
      return `${color}${code}`;
    },

    async renderPageWrapper() {
      await this.renderPage();
      await this.renderTextLayer();
    },

    setElementFocus(element) {
      this.$emit('set-element-focus', element)
    }

  },

  created() {
    this.setInitialViewport();
    subscribeTo('ae:input-touch-end', this.onInputTouchEnd);
  },

  mounted() {
    this.updateElementBounds();
    this.renderPageWrapper();
  },

  beforeDestroy() {
    this.destroyPage(this.page);
    unsubscribeFrom('ae:input-touch-end', this.onInputTouchEnd);
  },
};
</script>

<template>
  <div
    class="hrbr-pdf-viewer-page-container"
    :class="{ 'hrbr-pdf-viewer-page-container--custom-overlay': this.hasCustomPageOverlay }"
    ref="pageContainer">
    <div
      class="hrbr-pdf-viewer-page"
      :data-page-id="page.pageId"
      :data-page-number="pageNumber"
      :data-actual-scale="actualPageScale"
      :style="pageStyle"
      data-pdf-page
      ref="page"
      @mouseenter="onPageMouseEnter"
      @mouseleave="onPageMouseLeave">

      <component
        v-if="hasCustomPageOverlay"
        :is="customPageOverlay.component"
        :params="customPageOverlay.params"
        :scale="actualPageScale"
        :parent-page="pageNumber"
        @set-element-focus="setElementFocus"
        class="custom-overlay"
      />

      <div class="hrbr-pdf-viewer-page__canvas-wrapper" :style="pageStyle">
        <canvas
          class="hrbr-pdf-viewer-page__canvas"
          v-bind="canvasAttrs"
          :style="pageStyle"
          ref="canvas">
        </canvas>
      </div>
      <div
        class="hrbr-pdf-viewer-page__text-layer"
        v-if="isTextLayer"
        :style="pageStyle"
        ref="textLayer"></div>

      <div
        class="hrbr-pdf-viewer-page__edit-layer"
        :class="{ 'hrbr-pdf-viewer-page__edit-layer--not-editable': !isEditingEnabled }"
        v-if="isEditMode"
        :style="pageStyle"
        @drop="onPageLayerDrop"
        @dragover.prevent
        ref="editLayer"
        data-edit-layer>
        <template v-for="annotation in draggableAnnotationsFilteredByPage">
          <template v-for="input in draggableInputs.filter((i) => i.id === annotation.inputitemid)">
            <VueDraggableResizable
              class-name="hrbr-draggable-annotation"
              class-name-dragging="hrbr-draggable-annotation--dragging"
              class-name-resizing="hrbr-draggable-annotation--resizing"
              class-name-active="hrbr-draggable-annotation--active"
              class-name-handle="hrbr-draggable-annotation__handle"
              :w="Math.round(annotation.width * actualPageScale)"
              :h="Math.round(annotation.height * actualPageScale)"
              :x="Math.round(annotation.x * actualPageScale)"
              :y="Math.round(annotation.y * actualPageScale)"
              :lock-aspect-ratio="input.itemfieldtype === 'CHECKBOXINPUT'"
              :min-width="
                Math.round(
                  getDraggableAnnotationMinDims(input.itemfieldtype).minWidth * actualPageScale,
                )
              "
              :min-height="
                Math.round(
                  getDraggableAnnotationMinDims(input.itemfieldtype).minHeight * actualPageScale,
                )
              "
              :draggable="true"
              :resizable="true"
              :parent="true"
              :handles="getDraggableHandles(input.itemfieldtype)"
              :key="annotation.id + '' + input.id + '' + scale"
              :data-id="annotation.id"
              :onDragStart="onDraggableAnnotationDragStartCallback"
              :onResizeStart="onDraggableAnnotationResizeStartCallback"
              @activated="onDraggableAnnotationActivated({ input })"
              @dragging="
                (left, top) => onDraggableAnnotationDragging({ id: annotation.id, left, top })
              "
              @resizing="
                (left, top, width, height) =>
                  onDraggableAnnotationResizing({
                    id: annotation.id,
                    left,
                    top,
                    width,
                    height,
                    fieldType: input.itemfieldtype,
                  })
              "
              @dragstop="
                (left, top) =>
                  onDraggableAnnotationDragStop({
                    id: annotation.id,
                    left,
                    top,
                    fieldType: input.itemfieldtype,
                  })
              "
              @resizestop="
                (left, top, width, height) =>
                  onDraggableAnnotationResizeStop({
                    id: annotation.id,
                    left,
                    top,
                    width,
                    height,
                    fieldType: input.itemfieldtype,
                  })
              ">
              <div
                v-if="input.itemfieldtype === 'CHECKBOXINPUT'"
                class="hrbr-draggable-annotation__holder hrbr-draggable-annotation__holder--checkbox"
                :style="{
                  fontSize: Math.round((annotation.fontsize / 2) * actualPageScale) + 'pt',
                  backgroundColor: getHexColorWithTransparency(input.itemcolor, '33'),
                }">
                <i class="fa-light fa-x" :style="{ fontWeight: 700, color: input.itemcolor }"></i>
              </div>

              <div
                v-else-if="draggableIconAnnotations.includes(input.itemfieldtype)"
                class="hrbr-draggable-annotation__holder hrbr-draggable-annotation__holder--icon"
                :class="{
                  'hrbr-draggable-annotation__holder--signature':
                    input.itemfieldtype === 'SIGNATUREINPUT',
                }"
                :style="{ backgroundColor: getHexColorWithTransparency(input.itemcolor, '33') }">
                <div
                  class="hrbr-draggable-annotation__content"
                  :style="{
                    fontSize: Math.round((annotation.fontsize / 2) * actualPageScale) + 'pt',
                  }">
                  <div class="hrbr-draggable-annotation__text-wrap">
                    <i :class="'far fa-' + input.icon"></i>
                  </div>
                </div>
                <div
                  class="hrbr-draggable-annotation__position"
                  :style="{
                    fontSize: Math.round(12 * actualPageScale) + 'px',
                    width: Math.round(20 * actualPageScale) + 'px',
                    backgroundColor: input.itemcolor,
                  }">
                  {{ getAnnotationIdx(input.id) + 1 }}
                </div>
              </div>

              <div
                v-else
                class="hrbr-draggable-annotation__holder hrbr-draggable-annotation__holder--text"
                :style="{ backgroundColor: getHexColorWithTransparency(input.itemcolor, '33') }">
                <div class="hrbr-draggable-annotation__content">
                  <div class="hrbr-draggable-annotation__text-wrap">
                    <span
                      class="hrbr-draggable-annotation__text"
                      :style="{
                        fontSize: Math.round(annotation.fontsize * actualPageScale) + 'pt',
                        fontFamily: availableFonts[annotation.fontfamily] || annotation.fontfamily,
                      }">
                      {{ getDraggableAnnotationTextValue(input) }}
                    </span>
                  </div>
                </div>
                <div
                  class="hrbr-draggable-annotation__position"
                  :style="{
                    fontSize: Math.round(12 * actualPageScale) + 'px',
                    width: Math.round(20 * actualPageScale) + 'px',
                    backgroundColor: input.itemcolor,
                  }">
                  {{ getAnnotationIdx(input.id) + 1 }}
                </div>
              </div>
            </VueDraggableResizable>
          </template>
        </template>
      </div>
    </div>

    <div class="hrbr-pdf-viewer-page__page-bottom" v-if="showPageBottomActions">
      <div class="hrbr-pdf-viewer-page__page-actions">
        <div class="hrbr-pdf-viewer-page__page-number">Page {{ pageIdx + 1 }}</div>
        <div
          class="hrbr-pdf-viewer-page__insert-pages"
          :class="{ 'hrbr-pdf-viewer-page__insert-pages--disabled': !isEditingEnabled }"
          @click="onPageInsert">
          <i class="fal fa-file-plus"></i>
        </div>
        <b-dropdown
          class="hrbr-pdf-viewer-page__dropdown-actions"
          :triggers="['click']"
          :mobile-modal="false"
          :append-to-body="true">
          <b-icon
            class="hrbr-pdf-viewer-page__dropdown-actions-trigger"
            icon="ellipsis-v"
            pack="fal"
            size="is-small"
            slot="trigger"></b-icon>
          <b-dropdown-item @click="onFileDownload" :disabled="!isEditingEnabled">
            <b-icon icon="arrow-circle-down" pack="fal"></b-icon>
            <span>Download this page</span>
          </b-dropdown-item>
          <b-dropdown-item @click="onPageRemove" :disabled="!isEditingEnabled">
            <b-icon pack="fal" icon="minus-circle"></b-icon>
            <span>Remove this page</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-pdf-viewer-page-container {
  &--custom-overlay {
    position: relative;

    .hrbr-pdf-viewer-page {
      border: 0;
    }
  }
}
.hrbr-pdf-viewer-page {
  width: 816px; /* 100% */
  height: 1056px; /* auto */
  position: relative;
  margin: 1px auto -8px;
  background-color: #fff;
  border: 9px solid transparent;
  background-clip: content-box;
  overflow: visible;
  box-sizing: content-box;

  &:last-child {
    margin-bottom: 0;
  }

  &__canvas-wrapper {
    overflow: hidden;
    box-shadow: 0 1px 3px rgb(0 0 0 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  }

  &__canvas {
    display: block;
    margin: 0;
    width: 100%;
    height: auto;
  }

  &__text-layer {
    position: absolute;
    text-align: initial;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.2;
    line-height: 1;
    -webkit-text-size-adjust: none;
    -moz-text-size-adjust: none;
    text-size-adjust: none;
    forced-color-adjust: none;

    span,
    br {
      color: transparent;
      position: absolute;
      white-space: pre;
      cursor: text;
      transform-origin: 0% 0%;
    }

    span.markedContent {
      top: 0;
      height: 0;
    }

    ::selection {
      background: var(--hrbr-primary-color-active);
    }

    br::selection {
      background: transparent;
    }

    .highlight {
      margin: -1px;
      padding: 1px;
      background-color: rgba(180, 0, 170, 1);
      border-radius: 4px;
    }

    .highlight.appended {
      position: initial;
    }
  }

  &__edit-layer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    &--not-editable {
      .hrbr-draggable-annotation {
        cursor: default;
      }
    }
  }

  &__page-bottom {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }

  &__page-actions {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__page-number {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    line-height: 1.2;
  }

  &__insert-pages {
    font-size: 12px;
    color: #02020299;
    display: inline-flex;
    cursor: pointer;

    &:hover {
      color: #222222;
    }

    &--disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  &__dropdown-actions-trigger {
    font-size: 16px;
    color: #02020299;
    cursor: pointer;

    &:hover {
      color: #222222;
    }
  }
}

.hrbr-draggable-annotation {
  position: absolute;
  border: 1px solid rgba(0, 102, 204, 0.6);
  touch-action: none;
  box-sizing: content-box;
  cursor: grab;

  &--dragging {
    background: rgba(45, 113, 173, 0.08);
    border-color: rgba(0, 102, 204, 1);

    .hrbr-draggable-annotation__holder {
      display: none;
    }
  }

  :deep(&__handle) {
    position: absolute;
    width: 14px;
    height: 14px;
    background: #fff;
    border: 2px solid rgba(45, 113, 172, 0.57);
    border-radius: 50%;
    transition: all 0.3s ease;
    box-sizing: border-box;
    cursor: pointer;

    &:hover {
      border-color: rgb(45, 113, 172, 0.8);
    }
  }

  :deep(&__handle-tl) {
    top: -14px;
    left: -14px;
    cursor: nw-resize;
  }

  :deep(&__handle-tm) {
    top: -14px;
    left: 50%;
    margin-left: -7px;
    cursor: n-resize;
  }

  :deep(&__handle-tr) {
    top: -14px;
    right: -14px;
    cursor: ne-resize;
  }

  :deep(&__handle-ml) {
    top: 50%;
    margin-top: -7px;
    left: -14px;
    cursor: w-resize;
  }

  :deep(&__handle-mr) {
    top: 50%;
    margin-top: -7px;
    right: -15px;
    cursor: e-resize;
  }

  :deep(&__handle-bl) {
    bottom: -14px;
    left: -14px;
    cursor: sw-resize;
  }

  :deep(&__handle-bm) {
    bottom: -104x;
    left: 50%;
    margin-left: -7px;
    cursor: s-resize;
  }

  :deep(&__handle-br) {
    bottom: -14px;
    right: -14px;
    cursor: se-resize;
  }

  &__holder {
    display: flex;
    height: 100%;
    user-select: none;
    pointer-events: none;
    overflow: hidden;
    box-sizing: border-box;

    &--text,
    &--checkbox {
      overflow: visible;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--text {
      .hrbr-draggable-annotation__content {
        overflow: visible;
      }

      .hrbr-draggable-annotation__text-wrap {
        width: 100%;
      }
    }

    &--icon {
      .hrbr-draggable-annotation__content {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &--signature {
      .hrbr-draggable-annotation__content {
        align-items: end;
        justify-content: start;
        padding-left: 4px;
        padding-bottom: 4px;
      }

      .hrbr-draggable-annotation__text-wrap {
        height: auto;
      }
    }
  }

  &__position {
    font-weight: 500;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: salmon;
    overflow: hidden;
  }

  &__content {
    font-family: inherit;
    font-size: 16px;
    line-height: 1;
    color: #2f5a89;
    text-align: center;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    /* flex: 1; */
    width: 100%;
    height: 100%;
    min-width: 0;
    overflow: hidden;
  }

  &__text-wrap {
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__text {
    display: block;
    width: 100%;
    text-align: left;
    line-height: 1.2;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 2px;
    overflow: hidden;
  }
}
.custom-overlay {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
}
</style>
