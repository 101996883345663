<script>
export default {
  name: 'HrbrPdfViewerThumbnail',

  props: {
    page: {
      type: Object,
      required: true,
    },
    pages: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
  },

  data() {
    return {
      src: null,
    };
  },

  computed: {
    viewport() {
      const { pdfjsPage } = this.page;
      return pdfjsPage.getViewport({ scale: 0.5 });
    },

    pageNumber() {
      const pageIdx = this.pages.findIndex((page) => page.pageId === this.page.pageId);
      if (pageIdx === -1) return 0;
      return pageIdx + 1;
    },

    isThumbnailActive() {
      return this.pageNumber === this.currentPage;
    },
  },

  watch: {
    page: 'destroyPage',
  },

  methods: {
    onThumbnailClick() {
      this.$emit('thumbnail-click', this.pageNumber);
    },

    async renderThumbnail() {
      if (this.renderTask) return;

      const { viewport } = this;

      const canvas = document.createElement('canvas');
      const canvasContext = canvas.getContext('2d');
      const renderContext = { canvasContext, viewport };

      canvas.width = Math.floor(viewport.width);
      canvas.height = Math.floor(viewport.height);

      try {
        const { pdfjsPage } = this.page;
        this.renderTask = pdfjsPage.render(renderContext);
        await this.renderTask.promise;

        this.src = canvas.toDataURL();

        // Zeroing the width and height can reduce memory consumption
        canvas.width = 0;
        canvas.height = 0;

        this.$emit('thumbnail-rendered', {
          page: this.page,
          pageNumber: this.pageNumber,
        });
      } catch (e) {
        this.destroyRenderTask();

        this.$emit('thumbnail-error', {
          page: this.page,
          pageNumber: this.pageNumber,
        });
      }
    },

    destroyPage(_newPage, page) {
      if (page) page.pdfjsPage.cleanup();
      this.destroyRenderTask();
    },

    destroyRenderTask() {
      if (!this.renderTask) return;
      this.renderTask.cancel();
      delete this.renderTask;
    },
  },

  created() {
    this.renderThumbnail();
  },

  beforeDestroy() {
    this.destroyPage(null, this.page);
  },
};
</script>

<template>
  <div
    class="hrbr-pdf-viewer-thumbnail"
    :class="{ 'hrbr-pdf-viewer-thumbnail--active': isThumbnailActive }"
    :data-page-number="pageNumber"
    @click="onThumbnailClick">
    <div class="hrbr-pdf-viewer-thumbnail__image">
      <img v-if="src" class="hrbr-pdf-viewer-thumbnail__img" :src="src" />
      <div v-else class="hrbr-pdf-viewer-thumbnail__placeholder">
        <div class="hrbr-pdf-viewer-thumbnail__loader">
          <i class="fa-light fa-spinner-third fa-spin"></i>
        </div>
      </div>
    </div>
    <div class="hrbr-pdf-viewer-thumbnail__number">{{ pageNumber }}</div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-pdf-viewer-thumbnail {
  margin-bottom: 10px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }

  &--active,
  &:hover {
    .hrbr-pdf-viewer-thumbnail__image {
      border-color: rgba(47, 90, 137, 0.6);
    }
  }

  &__image {
    border: 5px solid transparent;
    border-radius: 2px;
    transition: all 0.2s;
  }

  &__img {
    max-width: 100%;
    height: auto;
    display: block;
  }

  &__number {
    font-size: 15px;
    color: #fff;
    text-align: center;
    line-height: 1.2;
    margin-top: 5px;
  }

  &__placeholder {
    background: #fff;
    background-clip: content-box;
    position: relative;

    &::before {
      content: '';
      display: block;
      padding-top: 125%;
    }
  }

  &__loader {
    font-size: 30px;
    color: var(--hrbr-primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
